import MainButton from "../buttonComponent/MainButton";

export default function ValidPop({title, desc, buttonsObject, showValidPop, bgcAction}) {

    return (
        <>
            {
                showValidPop &&
                <div onClick={bgcAction} className="bluredDiv"></div>
            }
                <div className="validPop">
                    <h2 className='validPop__title'>{title}</h2>
                    {desc &&
                        <p className="validPop__desc">{desc}</p>
                    }
                    <div className="validPop__buttonContainer">
                        { (buttonsObject && buttonsObject.length <= 2) &&
                            buttonsObject.map((button, k) => {
                                return (
                                    <MainButton buttonAction={button.action} buttonIcon={button.icon} key={k} className={`validPop__buttonContainer_${button.className}`} buttonText={button.text}/>
                                )
                            })
                        }
                </div>
            </div>
        </>
    )
}

import { useRef, useState } from 'react';
import DeleteCatalog from './DeleteCatalog';
import EditCatalog from './EditCatalog';
import CreateCatalog from './CreateCatalog';
import axios from 'axios';
export default function PersonalCatalog({ personalCatalogList }) {

    const catalogNameRef = useRef();

    const [createCatalog, setCreateCatalog] = useState(false);
    const [editCatalog, setEditCatalog] = useState(false);
    const [deleteCatalog, setDeleteCatalog] = useState(false);
    const [catalogSelectedId, setCatalogSelectedId] = useState('');


    const handleSelectedCatalog = (e) => {
        console.log(e.target.value);
        setCatalogSelectedId(e.target.value);
    }

    const handleCreateCatalog = () => {
        console.log('create catalog');
        console.log(catalogNameRef.current.value);

        axios.post(`${process.env.REACT_APP_VALID_PERSONAL_CATALOG}`, {
            name: catalogNameRef.current.value
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const handleEditCatalog = () => {
        console.log('edit catalog');
        console.log(catalogNameRef.current.value);
        console.log(catalogSelectedId);

        axios.post(`${process.env.REACT_APP_VALID_PERSONAL_CATALOG}`, {
            id: catalogSelectedId,
            name: catalogNameRef.current.value
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const handleDeleteCatalog = () => {
        console.log('delete catalog');
        console.log(catalogSelectedId);

        axios.post(`${process.env.REACT_APP_DELETE_PERSONAL_CATALOG}`, {
            id: catalogSelectedId
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>
            <section className='personalCatalog'>
                {
                    createCatalog &&
                    <CreateCatalog handleCreateCatalog={handleCreateCatalog} catalogNameRef={catalogNameRef} />
                }

                {
                    editCatalog &&
                    <EditCatalog handleEditCatalog={handleEditCatalog} catalogNameRef={catalogNameRef} handleSelectedCatalog={handleSelectedCatalog} personalCatalogList={personalCatalogList} />
                }

                {
                    deleteCatalog &&
                    <DeleteCatalog handleDeleteCatalog={handleDeleteCatalog} handleSelectedCatalog={handleSelectedCatalog} personalCatalogList={personalCatalogList} />
                }

                <div className='personalCatalogOption'>
                    <p>Catalogs personnels</p>
                    <div className='options'>
                        <p className='button' onClick={() => { setCreateCatalog(!createCatalog); setDeleteCatalog(false); setEditCatalog(false) }}>Créer un catalogue</p>
                        <p className='button' onClick={() => { setCreateCatalog(false); setDeleteCatalog(false); setEditCatalog(!editCatalog) }}>Modifier un catalogue</p>
                        <p className='button' onClick={() => { setCreateCatalog(false); setDeleteCatalog(!deleteCatalog); setEditCatalog(false) }}>Supprimer un catalogue</p>
                    </div>
                </div>

            </section>
        </>
    )

}

import "../../../styles/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    faCircleCheck,
    faClock,
    faCopy,
    faDownload, faEllipsisV,
    faEuroSign, faGear, faPaperPlane,
    faPrint,
    faPlus, faXmark
} from "@fortawesome/free-solid-svg-icons";

function CreditCard(props) {

    let navigate = useNavigate();


    useEffect(() => {
        handleMark(props.state)
    })
    //split date
    let day = props.creditDate?.split("-")[2];
    let month = props.creditDate?.split("-")[1];
    let year = props.creditDate?.split("-")[0];

    let xmark = 'xmark' + props.creditId
    let option = 'option' + props.creditId
    let stateTextId = 'state-text' + props.creditId
    let mark = 'mark' + props.creditId

    const openCredit = (valid) => {
        console.log(valid);
        if (valid === 0) {
            window.open(`/creditList/credit/${props.creditId}`, '_self');
        } else {
            if (window.confirm('Cet avoir est vérouiller car il a été passé en facture.\r\n Voulez-vous malgré tout le dévérouiller ?')) {
                window.open(`/creditList/credit/${props.creditId}`, '_self');
            }
        }
    }

    const handleOptions = (id) => {
        let findXmark = 'xmark' + props.creditId
        let findOption = 'option' + props.creditId

        if (document.getElementById(findOption).classList.contains('option')) {
            document.getElementById(findOption).classList.remove('option')
            document.getElementById(findXmark).classList.remove('ellipsis-xmark')
            document.getElementById(findOption).classList.add('hidden')
        } else {
            document.getElementById(findOption).classList.add('option')
            document.getElementById(findXmark).classList.add('ellipsis-xmark')
            document.getElementById(findOption).classList.remove('hidden')
        }
    }

    const handleMark = async (val, id) => {

        let stateText = document.getElementById(stateTextId);
        let stateMark = document.getElementById(mark);

        if (val != null) {
            const customHeaders = {
                'content-type': 'application/json',
            };

            await axios.post(`http://localhost/CAPBOXV2-SERVER/index.php?action=c-state-credit&id=${id}&state=${val}`, null, customHeaders)
                .then(response => {
                    // console.log("log de response data 1", response.data);
                    // navigate("/creditList");
                })
                .catch(error => {
                    console.log(error);
                })
        }

        stateMark.classList.remove('paid')
        stateMark.classList.remove('unpaid')
        stateMark.classList.remove('draft')
        stateMark.classList.remove('canceled')


        switch (val) {
            case 'paid':
                stateText.innerText = 'Payé';
                stateMark.classList.add('paid')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            case 'unpaid':
                stateText.innerText = 'Non payé';
                stateMark.classList.add('unpaid')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            case 'draft':
                stateText.innerText = 'Brouillon';
                stateMark.classList.add('draft')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            case 'canceled':
                stateText.innerText = 'Annulé';
                stateMark.classList.add('canceled')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            default:
                break;
        }
    }

    const duplicate = (id) => {
        window.open(`${process.env.REACT_APP_CREDIT_DUP}${id}`, '_self');
    }

    const printCredit = (id) => {
        axios.get(`http://localhost/CAPBOXV2-SERVER/index.php?action=c-credit-print&id=${id}`)
            .then(response => {
                console.log(response.data);
                navigate("/creditList");
            })
            .catch(error => {
                console.log(error);
            })
    }

    const deleteCredit = (id) => {
        document.getElementById(id).classList.add('hidden');
        axios.post(`${process.env.REACT_APP_CREDIT_DELETE}${id}`)
            .then(response => {
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })

    }

    return (

        <div className="card" key={props.creditId} id={props.creditId}>
            <div className="ellipsis" >
                <div className="ellipsis-button" onClick={() => handleOptions(props.creditId)}>
                    <FontAwesomeIcon icon={faEllipsisV} />
                </div>
                <div id={xmark} className="hidden" onClick={() => handleOptions(props.creditId)}>
                    <FontAwesomeIcon icon={faXmark} />
                </div>
                <div id={option} className="hidden">
                    <div className="modify">
                        <Link to={`/credit/${props.creditId}`} className="creditLink">
                            <p><FontAwesomeIcon icon={faGear} />Modifier</p>
                        </Link>
                    </div>
                    <div className="mark">
                        <p className="paid" onClick={() => handleMark('paid', props.creditId)}><FontAwesomeIcon icon={faCircleCheck} />Marquer comme payé</p>
                        <p className="unpaid" onClick={() => handleMark('unpaid', props.creditId)}><FontAwesomeIcon icon={faCircleCheck} />Marquer comme non payé</p>
                        <p className="canceled" onClick={() => handleMark('canceled', props.creditId)}><FontAwesomeIcon icon={faCircleCheck} />Marquer comme annulé</p>
                    </div>
                    <div className="download">
                        <p><FontAwesomeIcon icon={faDownload} />Télécharger</p>
                        <p><FontAwesomeIcon icon={faPaperPlane} />Envoyer</p>
                        <p onClick={() => printCredit(props.creditId)}><FontAwesomeIcon icon={faPrint} />Imprimer</p>
                    </div>
                    <div className="duplicate">
                        <p onClick={() => duplicate(props.creditId)}><FontAwesomeIcon icon={faCopy} />Dupliquer</p>
                    </div>
                    <div className="delete">
                        <p className="trash-can" onClick={() => deleteCredit(props.creditId)}><FontAwesomeIcon icon={faPlus} />Supprimer</p>
                    </div>
                </div>
            </div>
            {/* <Link to={`/quote/${props.quoteId}`}> */}
            <div className="tileCard" onClick={() => openCredit(props.valid)}>
                <div className="state valid">
                    <p className="ref">AVOIR N° <span>{props.creditRef}</span></p>
                    <p className="state-text hidden" id={stateTextId}></p>
                    <span id={mark} className="hidden"><FontAwesomeIcon icon={faCircleCheck} /></span>

                </div>
                <div className="name">
                    <p>{props.contactLastName} {props.contactFirstName} {props.societyContact ? `- ${props.societyContact}` : ""}</p>
                </div>
                <div className="price">
                    <FontAwesomeIcon icon={faEuroSign} />
                    <p className="ht">{parseFloat(props.totalHt?.replace(',', '.')).toFixed(2)} € HT</p>
                    <p className="ttc">{parseFloat(props.totalTtc?.replace(',', '.')).toFixed(2)} € TTC</p>
                </div>
                <div className="date">
                    <FontAwesomeIcon icon={faClock} />
                    <p>{day}/{month}/{year}</p>
                </div>
            </div>

        </div>
    );




}

export default CreditCard
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';

function CalendarPicker(props) {

    const onChangeDate = (newDate) => {
        props.onChangeDate(newDate);
    }

    return (
        <div>
            <Calendar
                onChange={onChangeDate}
                value={props?.value}
                className={props?.show ? "" : "hide"}
            />
        </div>
    );
}

export default CalendarPicker;
export default function DepositStateFilter({ validSelected }, { valid }) {
    const validObject = [
        { valid: "", name: "Tous les acomptes" },
        { valid: 1, name: "Acomptes payés" },
        { valid: 0, name: "Acomptes impayés" }
    ];
        
    return (
        <>
            <div id="stateFilter" className="type">
                {validObject.map((item, index) => (
                    <div
                        id={`valid-${index}`}
                        onClick={(e) => validSelected(e, item.valid)}
                        key={index}
                        className="left"
                    >
                        <h3>{item.name}</h3>
                    </div>
                ))}
            </div>
        </>
    );
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export default function CreateCatalog({ handleCreateCatalog, catalogNameRef }) {
    return (
        <>
            <div className='formDiv'>
                <FontAwesomeIcon className='button' onClick={handleCreateCatalog} icon={faCheck} />
                <input type="text" ref={catalogNameRef} placeholder='Entrer le nom' />
            </div>

        </>
    )
}
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faChevronRight, faEnvelope, faGear, faXmark} from "@fortawesome/free-solid-svg-icons"
import {useLocation, useNavigate} from "react-router-dom";
import NavButton from "./navButton";
import Logo from "../../assets/images/CapAchatLogo.svg"
import useAuth from "../../hooks/useAuth";
import {useAuthState} from "../../context/authContext";
import roleVerify from "../../utils/roleVerify";

export default function Nav({setShowNav}) {
    const {logout} = useAuth();
    const {roleId} = useAuthState();

    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const handleDisconnect = () => {
        logout();
        navigate('/login')
    }

    const navButtonList = [
        {path: path, name: "tableau de bord", link: "/", roleIdList: ["1", "2", "3", "4", "5", "6"]},
        {path: path, name: "clients", link: "/contactsList", roleIdList: ["1", "2", "3"]},
        {path: path, name: "vos devis", link: "/quotesList", roleIdList: ["1", "2", "3", "4", "5", "6"]},
        {path: path, name: "vos factures", link: "/invoicesList", roleIdList: ["1", "2", "3", "4", "5", "6"]},
        {path: path, name: "marges", link: "/marginsList", roleIdList: ["1", "2", "3"]},
        {path: path, name: "dépenses", link: "/ordersList", roleIdList: ["1", "2", "3"]},
        {path: path, name: "avoirs", link: "/creditList", roleIdList: ["1", "2", "3"]},
        {path: path, name: "acomptes", link: "/depositsList", roleIdList: ["1", "2", "3", "4", "5", "6"]},
        {path: path, name: "catalogues", link: "/catalogList", roleIdList: ["1", "2", "3"]},
    ]

    return (
        <>
            <div className="navigation">
                <div className="navigation__backButtonContainer">
                    <h3 className="navigation__backButtonContainer_backButton" onClick={() => setShowNav(false)}>Retours
                        au site <FontAwesomeIcon className="navigation__backButtonContainer_backButton_icon"
                                                 icon={faChevronRight}/></h3>
                    <hr className="navigation__backButtonContainer_line"/>
                </div>

                <div className="navigation__links">
                    <ul className="navigation__links_ul">

                        {
                            navButtonList.map((navButton, index) => {
                                return (
                                    roleVerify(navButton.roleIdList, roleId) &&
                                    <NavButton key={index} path={navButton.path} activePathName={navButton.link}
                                               name={navButton.name} link={navButton.link}/>
                                )
                            })
                        }

                        {

                            // <>
                            //     <li className="navigation__links_ul_li"><Link className={`navigation__links_ul_li_link ${path === '/' && 'activeNavButton'}`} to="/"><span className={`navigation__links_ul_li_link_span`}></span>tableau de bord</Link></li>
                            //     <NavButton path={path} activePathName={"/contact"} name={"clients"} link={'/contactsList'} />
                            //     <NavButton path={path} activePathName={"/quote"} name={"vos devis"} link={'/quotesList'} />
                            //     <NavButton path={path} activePathName={"/invoice"} name={"vos factures"} link={'/invoicesList'} />
                            //     <NavButton path={path} activePathName={"/margin"} name={"marges"} link={'/marginsList'} />
                            //     <NavButton path={path} activePathName={"/order"} name={"dépenses"} link={'/ordersList'} />
                            //     <NavButton path={path} activePathName={"/credit"} name={"avoirs"} link={'/creditList'} />
                            //     <NavButton path={path} activePathName={"/deposit"} name={"acomptes"} link={'/depositsList'} />
                            //     <NavButton path={path} activePathName={"/catalog"} name={"catalogues"} link={'/catalogList'} />
                            // </>

                        }
                    </ul>
                    <div className="navigation__links_buttons">
                        <p onClick={() => navigate('/settings')} className="navigation__links_buttons_link"
                           >paramètres<FontAwesomeIcon className="navigation__links_buttons_link_icon"
                                                                     icon={faGear}/></p>
                        <button onClick={handleDisconnect}
                                className="navigation__links_buttons_button">déconnexion<FontAwesomeIcon
                            className="navigation__links_buttons_button_icon" icon={faXmark}/></button>
                    </div>
                </div>
                <div className="navigation__footer">
                    <p className="navigation__footer_p">Une solution proposé par</p>
                    <img className="navigation__footer_logo" src={Logo} alt="logo cap-achat"/>
                    <div className="navigation__footer_buttons">
                        <button className="navigation__footer_buttons_button">En savoir +</button>
                        <button className="navigation__footer_buttons_button"><FontAwesomeIcon
                            className="navigation__footer_buttons_button_icon" icon={faEnvelope}/>Contact
                        </button>
                    </div>
                    <p className="navigation__footer_p">2024 - CapBox - Tous droits réservés</p>
                </div>
            </div>
        </>
    )
}
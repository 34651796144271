import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    faCircleCheck, faClock,
    faEllipsis,
    faGear,
    faPaperPlane,

    faPrint,
    faTrashCan,
    faXmark
} from "@fortawesome/free-solid-svg-icons";


export default function DepositCard(props) {
    const navigate = useNavigate();
    const [deposits, setDeposits] = useState(props.deposits);
    const [showPopUp, setShowPopUp] = useState("");

    useEffect(() => {
        setDeposits(props.deposits);
    }, [props.deposits])

    // const handleUpdateState = (id, state) => {
    //     // console.log(id, state);
    //     props.setStateUpdtate(true)
    //     axios.post(`${process.env.REACT_APP_DEPOSIT_STATE}${id}&state=${state}`)
    //         .then(response => {
    //             console.log(response.data);
    //         })
    // }

    const handleDelet = (k, id) => {
        const unShowCard = document.querySelector(`#card${k}`);
        unShowCard.classList.add("unShow");
        axios.post(`${process.env.REACT_APP_DESACITVATE_DEPOSIT}${id}`)
            .then(response => {
                console.log(response.data);
            })
    }

    return (
        <>
            <div className="cards-list">
                {deposits?.map((deposit, k) => {

                    let day = deposit.depositDate.split("-")[2];
                    let month = deposit.depositDate.split("-")[1];
                    let year = deposit.depositDate.split("-")[0];

                    return (
                        // <div key={k} className={`card ${showCard === k && "unShow"}`}>

                        <div id={`card${k}`} key={k} className="card">


                            {
                                showPopUp === k &&
                                <div className="cardOptionsPop">
                                    <div className="buttonGroup firstLine">
                                        <p onClick={() => navigate(`/depositsList/deposit/${deposit.id}-deposit`)} className="button"><FontAwesomeIcon icon={faGear} />Modifier</p>
                                        {
                                            showPopUp === k &&
                                            <p onClick={() => setShowPopUp("")} className="closeCardOption"><FontAwesomeIcon icon={faXmark} /></p>
                                        }
                                    </div>
                                    <hr className="line" />
                                    {/*<div className="buttonGroup">*/}
                                    {/*    <p onClick={() => handleUpdateState(deposit.id, 1)} className="button"><FontAwesomeIcon className="inconColorGreen" icon={faCircleCheck} />Marquer comme finalisé</p>*/}
                                    {/*    <p onClick={() => handleUpdateState(deposit.id, 0)} className="button"><FontAwesomeIcon className="inconColorRed" icon={faCircleCheck} />Marquer comme annulé</p>*/}
                                    {/*</div>*/}
                                    <hr className="line" />
                                    <div className="buttonGroup">
                                        <p className="button"><FontAwesomeIcon icon={faPrint} />Télécharger</p>
                                        <p className="button"><FontAwesomeIcon icon={faPaperPlane} />Envoyer</p>
                                        <p className="button"><FontAwesomeIcon icon={faPrint} />Imprimer</p>
                                    </div>
                                    <hr className="line" />
                                    {/*<div className="buttonGroup">*/}
                                    {/*    <p className="button"><FontAwesomeIcon icon={faCopy} />Dupliquer</p>*/}
                                    {/*    <p className="button"><FontAwesomeIcon icon={solid('shuffle')} />Transférer</p>*/}
                                    {/*</div>*/}
                                    <hr className="line" />
                                    <p onClick={() => handleDelet(k, deposit.id)} className="button"><FontAwesomeIcon icon={faTrashCan} />Supprimer</p>
                                </div>
                            }

                            <div onClick={() => navigate(`/depositsList/deposit/${deposit.id}-deposit`)} className="tileCard">
                                <div className="state valid">
                                    <p className="ref">ACOMPTE N° <span>{deposit.ref}</span></p>
                                    <div className="statTxtGroupCard">
                                        <p className="state-text">{deposit?.valid === "1" ? "Payé" : "Impayé"}</p>
                                        <FontAwesomeIcon icon={faCircleCheck} className={`${deposit?.valid === '1' ? "inconColorGreen" : "inconColorRed"}`} />
                                    </div>
                                </div>
                                <div className="name">
                                    <p>{deposit.contact.civility} {deposit.contact.firstName} {deposit.contact.lastName} {deposit.contact.society ? `- ${deposit.contact.society}` : ""}</p>
                                </div>
                                <div className="price">
                                    <p className="percent">{deposit.rate} %</p>
                                    <p className="ttc">{deposit.totalTtc} € TTC</p>
                                </div>
                                <div className="date">
                                    <FontAwesomeIcon icon={faClock} />
                                    <p>{day}/{month}/{year}</p>
                                </div>
                            </div>
                            {
                                showPopUp === k ?
                                    <p onClick={() => setShowPopUp("")} className="cardOptionsPopButton"><FontAwesomeIcon icon={faXmark} /></p>
                                    :
                                    <p onClick={() => setShowPopUp(k)} className="cardOptionsPopButton"><FontAwesomeIcon icon={faEllipsis} /></p>
                            }

                        </div>

                    )
                })
                }
            </div>
        </>
    )
}
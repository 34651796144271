import {createContext} from "react";

export const DeleteApiContext = createContext(null);

export default function DeleteApiProvider(props) {
    return (
        <DeleteApiContext.Provider
            value={{
                deleteItem: API.deleteItem,
            }}>
            {props.children}
        </DeleteApiContext.Provider>
    );
}

const capboxApi = process.env.REACT_APP_CAPBOXV2_SERVER;

const token = localStorage.getItem("acces_token");
const headers = {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        ...(token && { "Authorization": "Bearer " + token }),
    },
}

const API = {
    deleteItem: async (endpoint, id) => {
        try {
            const response = await fetch(`${capboxApi}${endpoint}&id=${id}`, headers);
            return await response.text();
        } catch (e) {
            console.log(e);
        }
    },


}


import { useEffect, useState } from 'react';
import axios from 'axios';
import ArticleCard from '../../components/customer/catalogues/ArticleCard';
import ItemPop from '../../components/customer/catalogues/cataloguePops/itemPop';
import CreatePersonalItemPop from '../../components/customer/catalogues/cataloguePops/createPersonalItemPop';
import PersonalCatalog from '../../components/customer/catalogues/personalCatalog/PersonalCatalog';
export default function CataloguesList() {

  const [catalogList, setCatalogList] = useState([]);
  const [showArticlePopup, setShowArticlePopup] = useState(false);
  //const [showEditPopup, setShowEditPopup] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [subfamilyList, setSubfamilyList] = useState([]);
  const [familyList, setFamilyList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState([]);
  const [switchedItem, setSwitchedItem] = useState(true);
  const [itemList, setItemList] = useState([]);
  const [personalItemList, setPersonalItemList] = useState([]);
  const [showCreatePersonalItemPop, setShowCreatePersonalItemPop] = useState(false);
  const [itemCopy, setItemCopy] = useState({});
  const [unitList, setUnitList] = useState([]);
  const [personalCatalogList, setPersonalCatalogList] = useState([]);


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_CATALOG_LIST}`)
      .then(response => {
        console.log(response.data);
        setCatalogList(response.data.listCatalogs)
        setSubfamilyList(response.data.listSubFamilies)
        setFamilyList(response.data.listFamilies)
        setBrandList(response.data.listBrands)
        setSuppliers(response.data.listSuppliers)
        setItemList(response.data.listItems)
        setPersonalItemList(response.data.personalItemList)
        setUnitList(response.data.unitList)
        setPersonalCatalogList(response.data.personalCatalogList)
      })
      .catch(error => {
        console.log(error);
      })
  }, []);


  const handleItemClick = (e) => {
    console.log(e);
    setSelectedArticle(e);
    setShowArticlePopup(true);
  }

  const handleEdit = () => {
    setIsEditable(!isEditable)
  }

  const handleShowCreatePersonalItemPop = (e) => {
    console.log('copie', e);
    setItemCopy(e);
    setShowCreatePersonalItemPop(true);
    setShowArticlePopup(false);
  }

  const handleClosePop = () => {
    setShowArticlePopup(false);
    setShowCreatePersonalItemPop(false);
  }

  return (
    <>
      {/* <TabBar /> */}
        {
          (showArticlePopup || showCreatePersonalItemPop) &&
          <div onClick={handleClosePop} className='bluredDiv'></div>
        }
        {
          showCreatePersonalItemPop &&
          <CreatePersonalItemPop
            setShowCreatePersonalItemPop={setShowCreatePersonalItemPop}
            itemCopy={itemCopy}
            subfamilyList={subfamilyList}
            familyList={familyList}
            brandList={brandList}
            supplierList={suppliers}
            unitList={unitList}
            personalCatalogList={personalCatalogList}
          />
        }
        {
          showArticlePopup &&
          <ItemPop
            personalCatalogList={personalCatalogList}
            unitList={unitList}
            catalogList={catalogList}
            handleShowCreatePersonalItemPop={handleShowCreatePersonalItemPop}
            handleEdit={handleEdit}
            isEditable={isEditable}
            setShowArticlePopup={setShowArticlePopup}
            selectedArticle={selectedArticle}
            suppliers={suppliers}
            subfamilyList={subfamilyList}
            familyList={familyList}
            brandList={brandList}
          />
        }

        <div className='contract'>
          <div className="toolbar">
            <div className="back-icon">
            </div>
            <div className="header">
              <h2>Catalogue</h2>

            </div>
            <div className="tools">
              <PersonalCatalog personalCatalogList={personalCatalogList} />
              <p className='createItemButton' onClick={() => handleShowCreatePersonalItemPop(null)}>Créer article</p>
              <p title={`Article ${switchedItem ? 'général' : 'personel'}`} className={`${switchedItem ? 'generalItemButton' : 'personalItemButton'} switchItemButton`} onClick={() => setSwitchedItem(!switchedItem)}>Changer d'item</p>
            </div>
            <hr />
          </div>
        </div>
        <div className='catalogList'>
          <h1 className='articleTitle'>{switchedItem ? "Tous les articles" : "Articles personnelles"}</h1>

          {
            switchedItem ? (
              itemList?.map((item, index) => {
                return (
                  <ArticleCard index={index} handleItemClick={handleItemClick} article={item} />
                )
              })

            ) : (
              personalItemList?.map((item, index) => {
                return (
                  <ArticleCard index={index} handleEdit={handleEdit} handleItemClick={handleItemClick} article={item} />
                )
              })
            )
          }
        </div>
    </>
  )


}
import SelectCatalog from './SelectCatalog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
export default function DeleteCatalog({ handleDeleteCatalog, handleSelectedCatalog, personalCatalogList }) {
    return (
        <>
        <div className='formDiv'>
            <FontAwesomeIcon className='button' onClick={handleDeleteCatalog} icon={faCheck} />
            <SelectCatalog handleSelectedCatalog={handleSelectedCatalog} personalCatalogList={personalCatalogList} />
        </div>
        </>
    )
}
import axios from "axios";
import { AppContext } from "./../../context/appContext";
import { useContext, useRef } from "react";
export default function MailPop(values) {

    const appContext = useContext(AppContext)

    const recipientRef = useRef("")
    const subjectRef = useRef("")
    const bodyRef = useRef("")
    
    const handleSubmit = async (e) =>{

        const customHeaders = {
            'content-type': 'application/json',
        };

        const values = {
            id: appContext.mailId,
            recipient: recipientRef.current.value,
            subject: subjectRef.current.value,
            body: bodyRef.current.value,
        }

        await axios.post(`${process.env.REACT_APP_SENDMAIL}`, values, customHeaders)
        .then(response => {
            console.log(response.data);
            console.log("C'est ok")
        })
        .catch(error => {
            console.log(error.response.data);
            console.log("C'est pas ok")

        })
    }

    const showMailPop = (id) => {
        console.log('id', id)
        if(appContext.showMailPop){
            appContext.setShowMailPop(false)
        }else{
            appContext.setShowMailPop(true)
        }
        appContext.setMailId(id)
    }

    return (
        <>
            <form className="mailpop" onSubmit={handleSubmit}>
                <div className="mailpopHeader">
                    <h3>Envoyer un mail</h3>
                    {/* <a href="#" onClick={appContext.setShowMailPop(false)}>X</a> */}
                    <button onClick={showMailPop}>x</button>
                </div>
                <div className="mailpopContainer">
                    
                        <label>Mail : </label>
                        <input ref={recipientRef} type="text" placeholder="Destinataire" />
                    
              
                        <label>Objet : </label>
                        <input ref={subjectRef} type="text" placeholder="Sujet" />
                 
                   
                        <label>Message : </label>
                        <textarea ref={bodyRef} placeholder="Message ..." />
                   
                    <button onClick={showMailPop}>Envoyer</button>
                </div>
            </form>
        </>
    )
}
export default function DepositSelectFilter({ handleSelectFilter }) {

    const filterObject = [
        { type: "", name: "Tous les acomptes", },
        { type: "DESC", name: "Date la plus proche", },
        { type: "ASC", name: "Date la moins proche", }
    ];
    return (
        <>
            <div style={{ justifyContent: "start" }} className="filter">
                <div className="filter-component">
                    <div>
                        <p>Trier par :</p>
                    </div>
                    <div>
                        <select onChange={handleSelectFilter}>
                            {filterObject.map((item, index) => (
                                <option key={index} value={item.type}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    )
}
export default function ItemPopForm({ handleEditItemSubmit, personalCatalogList, brandList, familyList, subFamilyList, suppliersList, selectedElement, refObject, unitList }) {
    console.log(selectedElement);


    const elements = unitList

    /* Maintenant, vous pouvez utiliser filter pour filtrer les éléments */
    const filteredElements = elements.filter(element => element.unit_id === selectedElement.auPrice);
    console.log('filteredElements', filteredElements);

    return (
        <>
            <form action="submit" className="form" onSubmit={handleEditItemSubmit}>
                <h2 className="title">Modifier un article</h2>
                <input className="name" type="text" defaultValue={selectedElement.name} ref={refObject.designationRef} placeholder="Nom de l'article" />
                <div className="container">

                    <div className="content">
                        <h3>Catégories</h3>

                        <select onChange={(e) => refObject.setCatalogSelected(e.target.value)} name="catalog" id="catalog">
                            <option value={selectedElement.personalCatalogId}>{selectedElement.catalog}</option>
                            {
                                personalCatalogList.map(element => {
                                    console.log();
                                    return (
                                        <option value={element.id}>
                                            {element.name}</option>
                                    )
                                })
                            }
                        </select>
                        <select onChange={(e) => refObject.setFamilySelected(e.target.value)} name="family" id="family">
                            <option value={selectedElement.family}>{selectedElement.family}</option>
                            {
                                familyList.map(element => {
                                    return (
                                        <option value={element.family}>
                                            {element.family}</option>
                                    )
                                })
                            }
                        </select>
                        <select onChange={(e) => refObject.setSubFamilySelected(e.target.value)} name="subfamily" id="subfamily">
                            <option value={selectedElement.subfamily}>{selectedElement.subfamily}</option>
                            {
                                subFamilyList.map(element => {
                                    return (
                                        <option value={element.subfamily}>
                                            {element.subfamily}</option>
                                    )
                                })
                            }
                        </select>
                        <select onChange={(e) => refObject.setSupplierSelected(e.target.value)} name="suppliers" id="suppliers">
                            <option value={selectedElement.supplier}>{selectedElement.supplier}</option>
                            {
                                suppliersList.map(element => {
                                    return (
                                        <option value={element.supplier}>
                                            {element.supplier}</option>
                                    )
                                })
                            }
                        </select>
                        <select onChange={(e) => refObject.setBrandSelected(e.target.value)} name="brand" id="brand">
                            <option value={selectedElement.brand}>{selectedElement.brand}</option>
                            {
                                brandList.map(element => {
                                    return (
                                        <option value={element.brand}>
                                            {element.brand}</option>
                                    )
                                })
                            }
                        </select>
                        <select onChange={(e) => refObject.setUnit(e.target.value)} name="unit" id="unit">
                            {/* <option value={selectedElement.auPrice}>{ }</option> */}
                            {
                                filteredElements.map(element => (
                                    <option key={element.unit_id} value={element.unit_id}>{element.unit_name}</option>
                                ))
                            }
                            {
                                unitList.map(element => {
                                    return (
                                        <option value={element.unit_id}>
                                            {element.unit_name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="content">
                        <h3>Valeurs</h3>
                        <input type="number" placeholder="prix net" defaultValue={selectedElement.netPrice} ref={refObject.netPriceRef} />
                        <input type="number" placeholder="prix de vente" defaultValue={selectedElement.basePrice} ref={refObject.basePriceRef} />
                        <input type="text" placeholder="Conditionnement" ref={refObject.packageItemRef} defaultValue={selectedElement.package} />
                    </div>
                    <div className="content">
                        <h3>Références</h3>
                        <input type="text" placeholder="Fabriquant" ref={refObject.refMarkerRef} defaultValue={selectedElement.refMaker} />
                        <input type="text" placeholder="distributeur" ref={refObject.refDistributorRef} defaultValue={selectedElement.refDistributor} />
                    </div>
                </div>
                <button className="button">Enregister</button>
            </form>
        </>
    )
}   
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import BasicInput from "../inputComponents/basicInput";
import MainButton from "../buttonComponent/MainButton";
import {faMagnifyingGlass, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import BasicSelect from "../selectComponents/basicSelect";

export default function SearchComponent({ refresh, onSearchSelectChange, onSearchSubmit, contentObject, interlocutor }) {
    const [showFilter, setShowFilter] = useState(false);
    useEffect(() => {
        if (interlocutor){
            interlocutor.forEach(e => {
                e.label = `${e.first_name} ${e.last_name}`
            })
        }
    }, [interlocutor])

    return (
        <>
            <div className="searchFilter" >
                <h3 onClick={() => setShowFilter(!showFilter)} className='searchFilter__title'>
                    <span className='searchFilter__title_titleSpan'><FontAwesomeIcon className='searchFilter__title_titleSpan_loopIcon' icon={faMagnifyingGlass} />RECHERCHER UN {contentObject?.ContentName}</span>
                    <FontAwesomeIcon id='plusIcon' className={`searchFilter__title_plusIcon ${showFilter && "iconRotate"}`} icon={faPlus} />
                </h3>

                <form id='searchFilterForm' className={`searchFilter__searchFilterForm ${showFilter && "active"}`} onSubmit={onSearchSubmit} >
                    <div className='searchFilter__searchFilterForm_inputContent'>
                        {
                            (contentObject && showFilter) &&
                            contentObject?.Content.map((e, index) => {
                                return (
                                    <BasicInput name={e.name} placeholder={e.title} refs={e.ref} key={index} type={e.type}/>
                                    // <input content='dece' className='searchFilter__searchFilterForm_inputContent_input' key={index} type={e.type} name={e.name} placeholder={e.title} ref={e.ref} />
                                )
                            })
                        }
                        {
                            (interlocutor && showFilter) &&
                            <BasicSelect action={onSearchSelectChange} defaultOptionName={"Suivi par"} options={interlocutor}/>
                            // <select className='searchFilter__searchFilterForm_inputContent_select' onChange={onSearchSelectChange} name="interloSearch">
                            //     <option className='searchFilter__searchFilterForm_inputContent_select_options' value="">Suivi par</option>
                            //     {
                            //         interlocutor.map((e, index) => {
                            //             console.log(e)
                            //             return (
                            //                 <option className='searchFilter__searchFilterForm_inputContent_select_options' key={index} value={e.id}>
                            //                     {e.first_name} {e.last_name}
                            //                 </option>
                            //             )
                            //         })
                            //     }
                            // </select>
                        }
                    </div>
                    <div className='searchFilter__searchFilterActions' style={!showFilter ? {height: "0"} : {}}>
                        <FontAwesomeIcon onClick={refresh} className="searchFilter__searchFilterActions_searchFilterReset" icon={faRotate} title="Rafraichir" />
                        <MainButton className={"searchFilter__searchFilterActions_button"} buttonText={"Valider"} />
                    </div>
                </form>
            </div>
        </>
    )
}
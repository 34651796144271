export default function SelectCatalog({ handleSelectedCatalog, personalCatalogList }) {
    return (
        <>
            <select className="persoCataSelect" onChange={(e) => handleSelectedCatalog(e)} name="selectCatalog" id="selectCatalog">
                <option className="persoCataOption">Choisir un catalog</option>

                {
                    personalCatalogList.map((catalog, index) => {
                        return (
                            <option className="persoCataOption" key={index} value={catalog.id}>{catalog.name}</option>
                        )
                    })
                }

            </select>
        </>
    )
}
import {useEffect} from "react";

export default function StateFilter({setState, stateObject, state}) {


    useEffect(() => {
        const children = document.querySelector("#stateFilter").children
        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            element.classList.remove("active");
        }
        if (state) {
            document.querySelector(`#valid-${stateObject.findIndex((e) => e.valid === state)}`).classList.add("active")
        }
    }, [state, stateObject])

    return (
        <>
            <div id="stateFilter" className="stateFilter">
                {stateObject.map((item, index) => (

                    <h3
                        id={`valid-${index}`}
                        onClick={(e) => setState(item.valid)}
                        key={index}
                        className="stateFilter__title">
                        {item.name}
                    </h3>

                ))}
            </div>
        </>
    );
}

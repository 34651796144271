import {createContext} from "react";

export const PostApiContext = createContext(null);

export default function PostApiProvider(props) {
    return (
        <PostApiContext.Provider
            value={{
                postInvoice: API.postInvoice,
                postInvoicesList: API.postInvoicesList,
                stateChange: API.stateChange,
                login: API.login,
                postQuotesList: API.postQuotesList,
                postQuote: API.postQuote,
                postCatalogueList: API.postCatalogueList,
                postDeposit: API.postDeposit,
                desactivateDeposit : API.desactivateDeposit,
                postMargin : API.postMargin,
                postMarginsList : API.postMarginsList,
                postContact: API.postContact,
                postContactsListFilter: API.postContactsListFilter,
                postSettings : API.postSettings,
                settingsInterlocutorPost : API.settingsInterlocutorPost,
                settingsAccountantPost : API.settingsAccountantPost,
            }}>
            {props.children}
        </PostApiContext.Provider>
    );
}
let token = localStorage.getItem("acces_token");
var headers = {
    "Content-Type": "application/json",
...(token && { "Authorization": "Bearer " + token }),
}
const capboxApi = process.env.REACT_APP_CAPBOXV2_SERVER;

const API = {
    postInvoice : async (invoice, id) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(invoice)
        }

        try {
            const response = await fetch(`${capboxApi}c-valid-invoice&id=${id}`, header);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseText = await response.text();
            //console.log('responseText', responseText);

            // Vérifier si la réponse est vide avant de la parser en JSON
            if (!responseText) {
                throw new Error('Empty response from server');
            }
            const result = JSON.parse(responseText);
            return result;
        } catch (error) {
            console.error('Error in postInvoice:', error);
            throw error;
        }
    },
    postInvoicesList: async (searchData) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(searchData)
        }
        try {
            const response = await fetch(`${capboxApi}c-invoices-list-filter`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            return await response.json();
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    stateChange: async (data) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data)
        }
        try {
            const response = await fetch(`${capboxApi}c-state-change`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            return await response.text();
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },

    login: async (values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values)
        }
        try {
            const response = await fetch(`${capboxApi}login`, header);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseText = await response.text();
            console.log('responseText', responseText);
            // Vérifier si la réponse est vide avant de la parser en JSON
            if (!responseText) {
                throw new Error('Empty response from server');
            }
            const result = JSON.parse(responseText);
            return result;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postQuotesList: async (searchData, sort, quoteState) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(searchData)
        }
        try {
            const response = await fetch(`${capboxApi}c-quotes-list-filter&sort=${sort}&state=${quoteState}`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            //console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postQuote: async (quote, id) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(quote)
        }
        try {
            const response = await fetch(`${capboxApi}c-valid-quote&id=${id}`, header);
            let text = await response.text();
            //console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postCatalogueList: async (currentPage, perPage, values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ values })
        }
        try {
            const response = await fetch(`${capboxApi}c-catalogs-list&currentPage=${currentPage}&perPage=${perPage}`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            //console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postDeposit: async (deposit, id) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(deposit)
        }
        try {
            const response = await fetch(`${capboxApi}c-valid-deposit&id=${id}`, header);
            let text = await response.text();
            // console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;

        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    desactivateDeposit: async (id) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({ id })
        }
        try {
            const response = await fetch(`${capboxApi}c-deactive-deposit&id=${id}`, header);
            let text = await response.text();
            // console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;

        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postMargin : async (id, values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values)
        }

        try {
            const response = await fetch(`${capboxApi}c-valid-margin&id=${id}`, header);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseText = await response.text();
            //console.log('responseText', responseText);

            // Vérifier si la réponse est vide avant de la parser en JSON
            if (!responseText) {
                throw new Error('Empty response from server');
            }
            const result = JSON.parse(responseText);
            return result;
        } catch (error) {
            console.error('Error in postInvoice:', error);
            throw error;
        }
    },
    postMarginsList: async (values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values)
        }
        try {
            const response = await fetch(`${capboxApi}c-margins-filter-list`, header);
            const responseText = await response.text();
            //console.log('responseText', responseText);

            // Vérifier si la réponse est vide avant de la parser en JSON
            if (!responseText) {
                throw new Error('Empty response from server');
            }
            const result = JSON.parse(responseText);
            return result;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postContact: async (id, contact) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(contact)
        }
        try {
            const response = await fetch(`${capboxApi}c-valid-contact-pop&id=${id}`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postContactsListFilter: async (state, values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values)
        }
        try {
            const response = await fetch(`${capboxApi}c-contacts-list-pop-filter&state=${state}`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    postSettings: async (values, imageFile, pdfFile) => {
        updateHeaders();

        // Créer une instance de FormData
        const formData = new FormData();

        // Ajouter toutes les autres valeurs (les autres champs du formulaire) à FormData
        for (const key in values) {
            formData.append(key, values[key]);
        }

        // Ajouter l'image à FormData
        if (imageFile) {
            formData.append('imageFile', imageFile);
        }

        // Ajouter le PDF à FormData, si nécessaire
        if (pdfFile) {
            formData.append('pdfFile', pdfFile);
        }

        const header = {
            method: "POST",
            headers: headers,
            body: formData
        }
        try {
            const response = await fetch(`${capboxApi}c-valid-settings`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            console.log("post response", text);
            let json = JSON.parse(text);
            console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    settingsInterlocutorPost: async (id, values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values)
        }
        try {
            const response = await fetch(`${capboxApi}c-valid-interlocutor&id=${id}`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
    settingsAccountantPost: async (id, values) => {
        updateHeaders();
        const header = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(values)
        }
        try {
            const response = await fetch(`${capboxApi}c-valid-accountant&id=${id}`, header);
            if (!response.ok) {
                throw new Error("HTTP status " + response.status);
            }
            let text = await response.text();
            console.log(text);
            let json = JSON.parse(text);
            // console.log(json);
            return json;
        } catch (e) {
            console.log("catch", e);
            return e;
        }
    },
}

function updateHeaders() {
    token = localStorage.getItem("acces_token");
    headers = {
        ...(token && {"Authorization": "Bearer " + token}),
    }
}


import axios from "axios";
import { useEffect, useRef, useState } from "react";
import DepositCard from "../../components/customer/deposit/depositCard";
import Loader from "../../components/addOns/Spinner";
import DepositStateFilter from "../../components/customer/deposit/depositStateFilter";
import DepositSelectFilter from "../../components/customer/deposit/depositSelectFilter";
import SearchComponent from "../../components/addOns/filterComponent/searchComponent";
//import Pagination from '../../components/addOns/Pagination';

function DepositsList() {

    // Refs
    const firstNameRef = useRef("");
    const lastNameRef = useRef("");
    const depositRef = useRef("");
    const quoteRef = useRef("");
    const invoiceRef = useRef("");

    // States
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState("");
    const [filter, setFilter] = useState("");
    const [interlocutor, setInterlocutor] = useState([]);
    const [searchSelect, setSearchSelect] = useState("");
    const [searchReset, setSearchReset] = useState(false);
    const [changeState, setChangeState] = useState(false);
    const [stateUpdtate, setStateUpdtate] = useState(false);

    // Consts
    const searchData = {
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        depositRef: depositRef.current.value,
        quoteRef: quoteRef.current.value,
        invoiceRef: invoiceRef.current.value,
        interlocutor: searchSelect
    }

    useEffect(() => {
        if (!changeState) {
            document.querySelector("#valid-0").classList.add("active");
            setChangeState(false)
        }
        setLoading(true);
        if (filter || valid || valid === 0) {
            console.log("valid", valid);
            axios.get(`${process.env.REACT_APP_DEPOSIT_LIST}&valid=${valid}&sort=${filter}`)
                .then(response => {
                    console.log(response.data);
                    setInterlocutor(response.data.listInterlocutors);
                    setDeposits(response.data.depositsList);
                    // setPerPage(response.data.perPage);
                    // setNbPages(response.data.nbPages);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        } else if (!filter || !valid || !searchSelect) {
            axios.get(`${process.env.REACT_APP_DEPOSIT_LIST}`)
                .then(response => {
                    //console.log(response.data);
                    setInterlocutor(response.data.listInterlocutors);
                    setDeposits(response.data.depositsList);
                    // setPerPage(response.data.perPage);
                    // setNbPages(response.data.nbPages);
                    setLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }
        setStateUpdtate(false)
        setSearchReset(false)
    }, [valid, filter, searchSelect, searchReset, stateUpdtate, changeState]);

    const validSelected = (e, item) => {
        setChangeState(true)
        const stateFilter = document.querySelector("#stateFilter")
        const parentElement = e.target.parentNode;
        if (stateFilter) {
            const children = stateFilter.children;
            for (let i = 0; i < children.length; i++) {
                const element = children[i];
                element.classList.remove("active");
            }
        }
        parentElement.classList.add("active");
        setValid(item);
    }

    const handleSelectFilter = (e) => {
        console.log(e.target.value);
        setFilter(e.target.value)
    }


    const test = () => {
        const formulaire = document.getElementById("searchFilterForm");
        formulaire.reset();
        setSearchReset(true)
    }

    const contentObject = {
        ContentName: "ACOMPTE",
        Content: [
            { name: "firstName", title: "Prénom", type: "text", ref: firstNameRef },
            { name: "lastName", title: "Nom", type: "text", ref: lastNameRef },
            { name: "refAconmpte", title: "Référence Acompte", type: "text", ref: depositRef },
            { name: "refDevis", title: "Référence Devis", type: "text", ref: quoteRef },
            { name: "refFacture", title: "Référence Facture", type: "text", ref: invoiceRef },
        ]
    }

    const onSearchSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        console.log("searchData", searchData);

        axios.post(`${process.env.REACT_APP_DEPOSIT_LIST}`, searchData)
            .then(response => {
                console.log(response.data);
                setDeposits(response.data.depositsList);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });

    }

    const onSearchSelectChange = (e) => {
        setSearchSelect(e.target.value)
    }

    const changePerPage = () => {
        // setPerPage(e.target.value);
        // setCurrentPage(1);
    };
    return (
        <>
            {/* <TabBar
                elem='deposits'
            /> */}
                {
                    loading &&
                    < Loader />

                }
                <div className="listPage">
                    <div className="toolbar">
                        <div className="title">
                            <h2>VOS <span>ACOMPTES</span></h2>
                        </div>
                    </div>
                    <DepositStateFilter validSelected={validSelected} valid={valid} />
                    <SearchComponent refresh={test} onSearchSelectChange={onSearchSelectChange} onSearchSubmit={onSearchSubmit} contentObject={contentObject} interlocutor={interlocutor} />
                    <DepositSelectFilter handleSelectFilter={handleSelectFilter} />
                    {
                        deposits &&
                        <DepositCard deposits={deposits} setStateUpdtate={setStateUpdtate} />
                    }
                </div>
                <div className='pagination'>
                            <label htmlFor='perPage'>Éléments par page </label>
                            <select name='perPage' onChange={(e) => changePerPage(e)}>
                            {/* <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}> */}
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                            </select>
                            {/* <Pagination 
                                // postsPerPage={perPage}
                                // setCurrentPage={setCurrentPage}
                                // currentPage={currentPage}
                                // nbPages={nbPages}
                            />*/}
                </div>
        </>
    )

}

export default DepositsList;
import { useRef, useState } from "react";
import axios from "axios";
import DeletButton from "../../../addOns/deletButton";
import ItemPopForm from "./itemPopForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons'

export default function ItemPop({ personalCatalogList, unitList, handleShowCreatePersonalItemPop, handleEdit, isEditable, setShowArticlePopup, selectedArticle, suppliers, subfamilyList, familyList, brandList }) {

    const designationRef = useRef();
    const refMarkerRef = useRef();
    const refDistributorRef = useRef();
    const packageItemRef = useRef();
    const netPriceRef = useRef();
    const basePriceRef = useRef();
    const autPriceRef = useRef();

    const [brandSelected, setBrandSelected] = useState("")
    const [familySelected, setFamilySelected] = useState("")
    const [subFamilySelected, setSubFamilySelected] = useState("")
    const [supplierSelected, setSupplierSelected] = useState("")
    const [catalogSelected, setCatalogSelected] = useState("")
    const [unit, setUnit] = useState("")


    const refObject = {
        designationRef,
        autPriceRef,
        refMarkerRef,
        refDistributorRef,
        packageItemRef,
        netPriceRef,
        basePriceRef,
        setBrandSelected,
        setFamilySelected,
        setSubFamilySelected,
        setSupplierSelected,
        setCatalogSelected,
        setUnit
    }

    console.log("selectedArticle", selectedArticle);

    const handleEditSubmit = (e) => {
        e.preventDefault();
        console.log('click');
        console.log(catalogSelected);
        const values = {
            id: selectedArticle.id,
            cataogueId: catalogSelected || selectedArticle.personalCatalogId,
            family: familySelected || selectedArticle.family,
            subfamily: subFamilySelected || selectedArticle.subfamily,
            brand: brandSelected || selectedArticle.brand,
            auPrice: unit || selectedArticle.auPrice,
            supplier: supplierSelected || selectedArticle.supplier,
            designation: designationRef.current?.value || selectedArticle.name,
            refMaker: refMarkerRef.current?.value || selectedArticle.refMaker,
            refDistributor: refDistributorRef.current?.value || selectedArticle.refDistributor,
            packageItem: packageItemRef.current?.value || selectedArticle.package,
            netPrice: netPriceRef.current?.value || selectedArticle.netPrice,
            basePrice: basePriceRef.current?.value || selectedArticle.basePrice,
        }

        const customHeaders = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        console.log("values", values);

        axios.post(`${process.env.REACT_APP_POST_PERSONAL_ITEM}`, values, customHeaders,)
            .then(response => {
                console.log("response post deposit", response.data);
                setShowArticlePopup(false)
                // navigate("/depositList")
            })
            .catch(error => {
                console.log(error);
            })
    }
    const test = process.env.REACT_APP_DELETE_PERSONAL_ITEM
    console.log(test);
    console.log("selectedArticle", selectedArticle);
    return (
        <>
            <section className="itemPop">
                <div className="popHeader">

                    <div className="options">

                        <FontAwesomeIcon className="copy icon" icon={faCopy} onClick={() => handleShowCreatePersonalItemPop(selectedArticle)} />

                        {
                            selectedArticle?.personalCatalogId &&
                            (
                                <>
                                    <FontAwesomeIcon className="edit icon" onClick={handleEdit} icon={faPenToSquare} />
                                    <DeletButton url={test} id={selectedArticle.id} />
                                </>
                            )
                        }
                    </div>
                    <FontAwesomeIcon className="cross" onClick={() => setShowArticlePopup(false)} icon={faXmark} />
                </div>
                {
                    isEditable ? (

                        <ItemPopForm setShowArticlePopup={setShowArticlePopup} unitList={unitList} refObject={refObject} handleEditItemSubmit={handleEditSubmit} suppliersList={suppliers} familyList={familyList} subFamilyList={subfamilyList} brandList={brandList} personalCatalogList={personalCatalogList} selectedElement={selectedArticle} />
                    ) : (
                        <div className="articleContent">
                            <h2 className="name">{selectedArticle.name}</h2>
                            <div className="content">
                                <div className="category">
                                    <h3 className="h3">Catégories</h3>
                                    <p><span className="span">Famille : </span>{selectedArticle.family}</p>
                                    <p><span className="span">Sous famille : </span>{selectedArticle.subfamily}</p>
                                    <p><span className="span">Catalogue : </span>{selectedArticle.catalog}</p>
                                    <p><span className="span">Fournisseur :</span>{selectedArticle.supplier}</p>
                                    <p><span className="span">Marque : </span>{selectedArticle.brand}</p>
                                </div>
                                <div className="values">
                                    <h3 className="h3">Valeurs</h3>
                                    <p><span className="span">Prix d'achat : </span>{selectedArticle.netPrice}</p>
                                    <p><span className="span">Prix de vente : </span>{selectedArticle.basePrice}</p>
                                    <p><span className="span">Unitée : </span>{selectedArticle.auPrice}</p>
                                    <p><span className="span">Conditionnement : </span>{selectedArticle.package}</p>
                                </div>
                                <div className="ref">
                                    <h3 className="h3">Références</h3>
                                    <p><span className="span">Ref distributeur : </span>{selectedArticle.refDistributor}</p>
                                    <p><span className="span">Ref fabriquant : </span>{selectedArticle.refMaker}</p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </section>
        </>
    )
}
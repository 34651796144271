import ContactsListPop from '../../components/customer/contacts/ContactsListPop';
import CataloguesListPop from '../../components/customer/catalogues/CataloguesListPop';
import MailPop from '../../components/addOns/MailPop';
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarPicker from '../../components/addOns/Calendar';
import { useCallback, useEffect } from 'react';
import CreditGroup from '../../components/customer/credits/creditGroup';
import { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/appContext";
import {
    faAngleDown, faCalendarDays,
    faCopy,
    faDownload,
    faGear,
    faPaperPlane,
    faPlus,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";

function Credit() {
    const appContext = useContext(AppContext);
    let { id } = useParams()

    let navigate = useNavigate();
    const [listTva, setListTva] = useState([]);
    const [allTva, setAllTva] = useState();
    const [contact, setContact] = useState();
    const [contacts, setContacts] = useState([]);
    const [subject, setSubject] = useState();
    const [discount, setDiscount] = useState();
    const [setDepositPrice] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [interlocutors, setInterlocutors] = useState([]);
    const [comment, setComment] = useState();
    const [catalogues] = useState([]);
    const [showCatalogues, setShowCatalogues] = useState(false);
    const [grpIndexSelected, setGrpIndexSelected] = useState();
    const [lineSelected, setLineSelected] = useState();
    const [lineIndexSelected, setLineIndexSelected] = useState();
    const [listUnit, setListUnit] = useState([]);
    const [subTotalHT, setSubTotalHT] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [setLinkDeposit] = useState()
    const [totalTTC, setTotalTTC] = useState(0);
    const [firstShowModal, setFirstShowModal] = useState(false);
    const [depositPercent, setDepositPercent] = useState();
    const [showModal, setShowModal] = useState(false);
    const [ref, setRef] = useState();
    const [credit, setCredit] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const [date, setDate] = useState();
    const [showMail, setShowMail] = useState(false);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_CREDIT_GET}${id}`)
            .then(response => {
                setCredit(response.data.credit)
                setRef(response.data.credit.ref);
                console.log("ref de credit", response.data.credit.ref);
                setLinkDeposit(response.data.linkDeposit)
                setListTva(response.data.listTva);
                setLinkDeposit(response.data.linkDeposit)
                console.log("response.data.listInterlocutors", response.data);
                setInterlocutors(response.data.listInterlocutors);
                setComment(response.data.credit.comment);
                if (response.data.credit.creditDate !== undefined || response.data.credit.creditDate !== null) {

                    let day = response.data.credit.creditDate.split("-")[2];
                    let month = response.data.credit.creditDate.split("-")[1];
                    let year = response.data.credit.creditDate.split("-")[0];

                    let newDate = month + "/" + day + "/" + year;
                    console.log("newDate", newDate);
                    setDate(new Date(newDate));
                }
                setDate(new Date());



                setListUnit(response.data.listUnit);
                // if (newDate = undefined || newDate == null) {
                //     setDate(new Date());
                // } else {
                //     setDate(new Date(newDate));

                // }



                setDiscountPrice();
                setDiscount(response.data.credit.discount);
                setContact(response.data.credit.contact);
                setSubject(response.data.credit.subject);
                // console.log("response.data.credit.subject", response.data.credit.subject);
            })
            .catch(error => {
                console.log("err", error);
            })

    });


    function removeGroup(index) {
        let newCredit = { ...credit };
        const filteredGroups = newCredit.groups.filter((grp, i) => {
            return i !== index;
        });
        newCredit.groups = filteredGroups;
        setCredit(newCredit);
        updateCredit();
    }

    function changeOrderGroup(index, direction) {
        let newCredit = { ...credit };
        let orderGroups = newCredit.groups;
        if (direction === "up") {
            let grp = orderGroups[index];
            orderGroups.splice(index, 1);
            orderGroups.splice(index - 1, 0, grp);
        } else {
            let grp = orderGroups[index];
            orderGroups.splice(index, 1);
            orderGroups.splice(index + 1, 0, grp);
        }

        newCredit.groups = orderGroups;
        setCredit(newCredit);
    }

    // function openDeposit(id) {
    //     window.open(`${process.env.REACT_APP_CREDIT_OPEN_DEPOSIT}` + id, '_self');
    // }

    function openCatalogues(index, grpIndex) {
        setShowCatalogues(true);
        setGrpIndexSelected(grpIndex);
        setLineIndexSelected(index);
        setLineSelected(credit.groups[grpIndex].lines[index]);
    }


    function onChangeDate(newDate) {
        setDate(newDate);
    }

    const updateCredit = useCallback((updatedGroup = undefined, newDiscount = undefined, newDepositPercent = undefined,
        updatedCredit = undefined, estimatedHours = undefined, hourlyCosts = undefined, purchaseCosts = undefined,
        fixedCosts = undefined, variableCosts = undefined) => {
        setCredit(credit => {
            let newCredit;
            if (updatedCredit !== undefined) {
                newCredit = { ...updatedCredit };
            } else {
                newCredit = { ...credit };
            }

            const newGroup = [...newCredit.groups];
            if (updatedGroup !== undefined) {
                newGroup[updatedGroup.index] = updatedGroup;
                newCredit = { ...newCredit, groups: newGroup };
            }
            let subtotalHT = 0;
            newGroup.forEach((grp) => {
                subtotalHT += parseFloat(grp.totalHT);
            });
            setSubTotalHT(subtotalHT);
            let newdiscount = 0;
            let newdiscountPrice = 0;
            if (newDiscount !== undefined) {
                newdiscount = newDiscount;
                setDiscount(newDiscount);
                newdiscountPrice = subtotalHT * (parseFloat(newDiscount) / 100);
            } else {
                newdiscount = discount;
                if (newdiscount !== undefined) {
                    newdiscountPrice = subtotalHT * (newdiscount / 100);
                } else {
                    newdiscountPrice = 0;
                }
            }
            setDiscountPrice(newdiscountPrice);
            let totalht = subtotalHT.toFixed(2) - newdiscountPrice.toFixed(2);
            setTotalHT(totalht);

            let newListTva = [...listTva];

            for (let k = 0; k < newListTva.length; k++) {
                newListTva[k].value = 0;
            }
            newCredit.groups.forEach((grp) => {
                grp.lines.foreach((line) => {
                    for (let k = 0; k < newListTva.length; k++) {
                        if (newListTva[k].id === line.vatId) {
                            let lineTva = ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) - ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) * (newdiscount / 100))) * newListTva[k].rate / 100;
                            newListTva[k].value = newListTva[k].value + lineTva;
                        }
                    }
                })
            });

            setListTva(newListTva);

            let amountTotalTva = 0;
            for (let k = 0; k < newListTva.length; k++) {
                amountTotalTva += newListTva[k].value;
            }
            let totalttc = parseFloat(totalht.toFixed(2)) + parseFloat(amountTotalTva.toFixed(2));
            setTotalTTC(totalttc);

            let newdepositPercent = 0;
            let newDepositPrice = 0;
            if (newDepositPercent !== undefined) {
                newdepositPercent = newDepositPercent;
                setDepositPercent(newdepositPercent);
                newDepositPrice = totalttc * newdepositPercent / 100;
                setDepositPrice(newDepositPrice);
            } else {
                newdepositPercent = depositPercent;
                newDepositPrice = totalttc * newdepositPercent / 100;
                setDepositPrice(newDepositPrice);
            }

            if (estimatedHours !== undefined) {
                newCredit = { ...newCredit, estimatedHours: estimatedHours };
            }
            if (hourlyCosts !== undefined) {
                newCredit = { ...newCredit, averageHourlyRateHT: hourlyCosts };
            }
            if (purchaseCosts !== undefined) {
                newCredit = { ...newCredit, purchaseCostsHT: purchaseCosts };
            }
            if (fixedCosts !== undefined) {
                newCredit = { ...newCredit, fixedCosts: fixedCosts };
            }
            if (variableCosts !== undefined) {
                newCredit = { ...newCredit, variableCosts: variableCosts };
            }
            return newCredit;
        });
    }, [depositPercent, discount, listTva, setDepositPrice]);

    const printCredit = () => {
        window.open(`${process.env.REACT_APP_CREDIT_PRINT}${credit.id}`, "_blank");
    }


    const onChangeInterlocutor = (e) => {
        let newInterlocutor = e.target.value;
        let newCredit = { ...credit, userId: newInterlocutor };
        setCredit(newCredit);
    }

    function duplicate() {
        window.open(`${process.env.REACT_APP_CREDIT_DUP}${credit.id}`, '_self');
    }
    const deleteCredit = (e) => {
        axios.post(`${process.env.REACT_APP_CREDIT_DELETE}${credit.id}`)
            .then(response => {
                console.log(response.data);
                navigate("/creditList");
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onValidCredit = async (e) => {

        e.preventDefault();

        const customHeaders = {
            'content-type': 'application/json',
        };
        // je recup bien la ref dans ref
        // console.log("jsp", ref);
        console.log("log de contact.id ", contact.id);
        const values = {
            ref: ref,
            destinataire: contact.id,
            date_emission: date.toLocaleDateString(),
            titre: subject,
            interlocuteur: credit.userId,
            Hremise1: discount,
            comment: comment,
            heuresTotFact: credit?.estimatedHours,
            tauxHoraireMoyen: credit?.averageHourlyRateHT,
            coutsAchat: credit?.purchaseCostsHT,
            fraisFixes: credit?.fixedCosts,
            fraisVariables: credit?.variableCosts,
            Htotalht1: totalHT,
            Htotalttc: totalTTC,
            groups: credit?.groups,
        };
        console.log("log de value ", values);
        // ici dans values je recup bien la ref
        // console.log(id);
        await axios.post(`${process.env.REACT_APP_CREDIT_POST}${id}`, values, customHeaders)
            .then(response => {
                // ici dans values je recup bien la ref mais la ref ne s'envoie pas elle est null dans le log de eresponse.data
                // peut-etre parce que le response.data recup les valeurs de values avant que je les modifie
                // console.log("values post ", values);
                console.log("log du response de post ", response.data);
                navigate("/creditList");
            })
            .catch(error => {
                console.log(error);
            })
    }
    const handleClickModal = () => {
        appContext.setTest(true)
        if (firstShowModal === false) {
            axios.get(`${process.env.REACT_APP_CREDIT_CONTACT_LIST}`)
                .then(response => {
                    setContacts(response.data.listContacts);
                });
            setFirstShowModal(true);
        }
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    function handleSelectedContact(index) {
        contacts.forEach(element => {
            if (element.id === index) {
                setContact(element);
            }
        });
        closeModal();
    }

    const addGrp = () => {
        let newCredit = { ...credit }
        let id = generateId();
        newCredit.groups.push(
            {
                id: id,
                creditId: 0,
                title: "",
                totalHT: 0,
                lines: [
                    {
                        id: "New0",
                        designation: "",
                        unit: "",
                        quantity: "1.00",
                        unitPrice: "0.00",
                        tva: 8,
                        totalHT: "0.00",
                        unitId: 1
                    },
                ]
            });
        setCredit(newCredit);
        updateCredit();
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        credit.groups.foreach((grp) => {
            while (grp.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });
        let newIdHtml = prefixIdLine + newIdLig;
        return newIdHtml;
    }
    const onChangeInput = (e) => {
        let value = e.target.value;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (e.target.name === "discount") {
            if (validated) {
                setDiscount(value);
            } else {
                value = 0;
                setDiscount(value);
            }
            updateCredit(null, value, null);
        } else if (e.target.name === "deposit") {
            if (validated) {
                setDepositPercent(value);
            } else {
                value = 0;
                setDepositPercent(value);
            }
            updateCredit(null, null, value);
        }
    }
    const changeAllTva = (i) => {
        switch (i) {
            case 1:
                setAllTva(3);
                break;
            case 2:
                setAllTva(5);
                break;
            case 3:
                setAllTva(2);
                break;
            case 4:
                setAllTva(1);
                break;
            default:
                setAllTva(8)
                break;
        }
    }
    const formatInput = (e) => {
        if (e.target.name === "discount") {
            var newDiscount = e.target.value;
            newDiscount = parseFloat(parseFloat(newDiscount.replace(',', '.')).toFixed(2));
            if (isNaN(newDiscount)) {
                newDiscount = "0.00";
            } else {
                newDiscount = parseFloat(newDiscount).toFixed(2);
            }
            setDiscount(newDiscount);
        } else if (e.target.name === "deposit") {
            var newDeposit = e.target.value;
            newDeposit = parseFloat(parseFloat(newDeposit.replace(',', '.')).toFixed(2));
            if (isNaN(newDeposit)) {
                newDeposit = "0.00";
            } else {
                newDeposit = parseFloat(newDeposit).toFixed(2);
            }
            setDepositPercent(newDeposit);
        }
    }

    function handleCloseCatalogues() {
        setShowCatalogues(false);
    }
    function handleSelectedArticle(article) {
        let item = article;
        setShowCatalogues(false);
        setCredit(credit => {
            let newCredit = { ...credit };
            let newLine = { ...lineSelected };
            newLine.name = item.name;
            newLine.quantity = 1;
            newLine.unitPrice = item.basePrice;
            newCredit.groups[grpIndexSelected].lines[lineIndexSelected] = newLine;
            console.log(newCredit);
            return newCredit;
        });
    }

    function closeMailPop() {
        setShowMail(false);
    }


    const handleClickCalendar = () => {
        if (showCalendar === true) {

            setShowCalendar(false);
        } else {
            setShowCalendar(true);
        }
        document.getElementById('abso').classList.add("abso")
    }

    const closePopup = () => {
        setShowCalendar(false);
        document.getElementById('abso').classList.remove("abso")
    };

    return (
        <>
            {/* <TabBar
                elem='credits'
            /> */}
            <ContactsListPop
                show={showModal}
                handleClose={closeModal}
                data={contacts}
                handleSelectedContact={handleSelectedContact}
            />
            <CataloguesListPop
                show={showCatalogues}
                handleCloseCatalogues={handleCloseCatalogues}
                handleSelectedArticle={handleSelectedArticle}
                data={catalogues}
            />
            <div className='main'>

                <MailPop
                    show={showMail}
                    creditId={credit.id}
                    closeMailPop={closeMailPop}
                />
                <ContactsListPop />
                <form onSubmit={(values) => onValidCredit(values)}>
                    <div className="contract">
                        <div id='abso' onClick={closePopup} className='abso'></div>
                        <div className="toolbar">
                            <div className="back-icon">
                                <Link to="/creditList">
                                    <img src={require("../../assets/images/icons/Picto/Retour.svg").default} alt="" />
                                </Link>
                            </div>
                            <div className="header">
                                <div className="ref">
                                    <div className="num">
                                        <h2>AVOIR&nbsp;N°</h2>
                                        <input type="text" name="ref" value={ref} onChange={(e) => setRef(e.target.value)} />
                                    </div>
                                    <div className="date" id='date'>
                                        {/* {
                                            (date) && (
                                                <CalendarPicker
                                                    onChangeDate={onChangeDate}
                                                    value={date}
                                                    show={showCalendar}
                                                />
                                            )
                                        } */}
                                        {date && showCalendar && (
                                            <CalendarPicker
                                                onChangeDate={onChangeDate}
                                                value={date}
                                                show={showCalendar}
                                            />
                                        )}
                                        <a href="#!" onClick={handleClickCalendar}><FontAwesomeIcon icon={faCalendarDays} /></a>
                                        <p>{date?.toLocaleDateString()}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tools">
                                <button className="save" type="submit">
                                    <p>Sauvegarder</p>
                                    <img src={require("../../assets/images/icons/Picto/Sauvegarder_white.svg").default} alt="" />
                                </button>
                                <div className="dropdown">
                                    <a href="#!" className="dropdown-toggle">
                                        <p>Plus d'actions</p>
                                        <FontAwesomeIcon icon={faGear} />
                                        <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: "8px" }} />
                                    </a>
                                    <div className="dropdown-menu">
                                        <a href="#!" onClick={printCredit}>
                                            <FontAwesomeIcon icon={faDownload} />
                                            <p>Télécharger</p>
                                        </a>
                                        <a href="#!" onClick={() => setShowMail(true)}>
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                            <p>Envoyer</p>
                                        </a>
                                        <a href="#!" onClick={() => duplicate()}>
                                            <FontAwesomeIcon icon={faCopy} />
                                            <p>Dupliquer</p>
                                        </a>
                                    </div>

                                </div>
                                <a className="garbage" href="#!" onClick={() => deleteCredit()} ><FontAwesomeIcon icon={faTrashCan} /></a>
                            </div>

                        </div >
                        <div className="block">
                            <div className="infos">
                                <h3>INFORMATIONS</h3>
                                <div className="infos-block">
                                    <div className="subject">
                                        <h4>Intitulé de l'avoir</h4>
                                        <input name="subject" placeholder="EX : AVOIR POUR UNE ISOLATION" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                    <div className="interlocutor">
                                        <div>
                                            <h4>Affaire suivie par</h4>
                                        </div>
                                        {
                                            interlocutors ? (
                                                <select name="destinataire" value={credit.userId} onChange={onChangeInterlocutor}>
                                                    {
                                                        interlocutors.map((interlocutor, i) => {
                                                            return (
                                                                <option key={i} value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>

                                                            )
                                                        })
                                                    }
                                                </select>
                                            ) : (
                                                <p>Pas d'interlocuteurs</p>
                                            )
                                        }

                                    </div>
                                    <div className="contact">
                                        <div>
                                            <h4>Client</h4>
                                        </div>
                                        <p>{contact?.lastName} {contact?.firstName} {(((contact?.lastName !== undefined && contact?.lastName !== null)
                                            || (contact?.firstName !== undefined && contact?.firstName !== null))
                                            && (contact?.society !== undefined && contact?.society !== null)) ? `-` : ""} {contact?.society}</p>
                                    </div>
                                    <div className="contact-search">
                                        <a href="#!" onClick={handleClickModal}>Rechercher un client<img src={require("../../assets/images/icons/Picto/search.svg").default} alt="" /></a>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className="contract-body">
                                <div className="container">
                                    {
                                        credit?.groups?.map((group, i) => {
                                            return (
                                                <CreditGroup
                                                    key={i}
                                                    index={i}
                                                    group={group}
                                                    id={group.id}
                                                    numero={i + 1}
                                                    designation={(group.title === undefined) ? "" : group.title}
                                                    lines={group.lines}
                                                    listTva={listTva}
                                                    allTva={allTva}
                                                    listUnit={listUnit}
                                                    creditId={credit.id}
                                                    updateCredit={updateCredit}
                                                    openCatalogues={openCatalogues}
                                                    removeGroup={removeGroup}
                                                    changeOrderGroup={changeOrderGroup}
                                                    credit={credit}
                                                />
                                            )
                                        })
                                    }
                                    <div className="new-group" onClick={addGrp}>
                                        <p>Ajouter un nouvel ensemble de lignes au avoirs</p>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </div>
                                    <div className="price">
                                        <div className="bold-block sub-total-ht">
                                            <p>Sous total HT</p>
                                            <p>{parseFloat(subTotalHT).toFixed(2)} €</p>
                                        </div>
                                        <div className="discount">
                                            <div>
                                                <p>Remise Globale</p>
                                                <input type="text" placeholder="0,00" name='discount' value={discount} onChange={onChangeInput} onBlur={formatInput} /> %
                                            </div>
                                            <p>{parseFloat(discountPrice).toFixed(2)} €</p>
                                        </div>
                                        <div className="bold-block total-ht">
                                            <p>Total HT</p>
                                            <p>{parseFloat(totalHT).toFixed(2)} €</p>
                                        </div>
                                        <div className="tva">
                                            {
                                                listTva && listTva.map((tva, i) => {
                                                    if (tva.id !== 8) {
                                                        return (
                                                            <div key={i}>
                                                                <p className='tva-rate' onClick={() => changeAllTva(i)}>TVA à {tva.rate} %</p>
                                                                <p>{parseFloat(tva.value).toFixed(2)} €</p>
                                                            </div>
                                                        );
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            }

                                        </div>
                                        <div className="bold-block total-ttc">
                                            <p>Total net TTC</p>
                                            <p>{parseFloat(totalTTC).toFixed(2)} €</p>
                                        </div>
                                    </div>
                                    <div className="comment">
                                        <h3>COMMENTAIRE AJOUTE AU DEVIS</h3>
                                        <textarea placeholder="Description" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form >
            </div >





        </>
    )






}

export default Credit
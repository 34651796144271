import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../../../styles/style.css";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";


function OrderLine(props) {

    const [index] = useState(props?.index);
    const [designation, setDesignation] = useState(props?.designation);
    const [quantity, setQuantity] = useState(props?.quantity);
    const [unitPrice, setUnitPrice] = useState(props?.unitPrice);


    useEffect(() => {
        setDesignation(props?.designation);
        setQuantity(props?.quantity);
        setUnitPrice(props?.unitPrice);
        let newTotalHT = (parseFloat(props?.unitPrice) * parseFloat(props?.quantity)).toFixed(2);
        let newLine = { ...props?.line, totalHT: newTotalHT, index: index };
        props.updateOrder(newLine);
    }, [props, index]);

    const onChange = (e) => {
        let value = e.target.value;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        // eslint-disable-next-line default-case
        switch (e.target.name) {
            case 'quantity':
                if (validated) {
                    setQuantity(value);
                } else {
                    value = 1;
                    setQuantity(value);
                }
                break;
            case 'unitPrice':
                if (validated) {
                    setUnitPrice(value);
                } else {
                    value = 0;
                    setUnitPrice(value);
                }
                break;
            case 'tva':
                let newTva = parseInt(e.target.value);
                var newLine = { ...props.line, vatId: newTva }
                /* setLine(newLine); */
                props.updateOrder(newLine);
                break;
            case 'unit':
                let newUnit = parseInt(e.target.value);
                newLine = { ...props.line, unitId: newUnit }
                /* setLine(newLine); */
                props.updateOrder(newLine);
                break;
        }
    }


    const formatInput = (e) => {
        // eslint-disable-next-line default-case
        switch (e.target.name) {
            case 'quantity':
                let newQuantity = e.target.value;
                newQuantity = parseFloat(parseFloat(newQuantity.replace(',', '.')).toFixed(2));
                if (isNaN(newQuantity)) {
                    newQuantity = "1.00";
                } else {
                    newQuantity = parseFloat(newQuantity).toFixed(2);
                }
                setQuantity(newQuantity);
                var newTotalHT = (parseFloat(newQuantity) * parseFloat(unitPrice)).toFixed(2);
                var newLine = { ...props.line, quantity: newQuantity, unitPrice: unitPrice, totalHT: newTotalHT, index: index }
                props.updateOrder(newLine);
                break;
            case 'unitPrice':
                let newUnitPrice = e.target.value;
                newUnitPrice = parseFloat(parseFloat(newUnitPrice.replace(',', '.')).toFixed(2));
                if (isNaN(newUnitPrice)) {
                    newUnitPrice = "0.00";
                } else {
                    newUnitPrice = parseFloat(newUnitPrice).toFixed(2);
                }
                newTotalHT = (parseFloat(quantity) * parseFloat(newUnitPrice)).toFixed(2);
                newLine = { ...props.line, quantity: quantity, unitPrice: newUnitPrice, totalHT: newTotalHT, index: index }
                props.updateOrder(newLine);
                setUnitPrice(newUnitPrice);
                break;
            case 'designation':
                let newDesignation = e.target.value;
                newLine = { ...props.line, name: newDesignation };
                props.updateOrder(newLine);
                break;
            case 'tva':
                let newTva = parseInt(e.target.value);
                if(newTva === null || newTva === undefined){
                    newTva = 8;
                }
                newLine = { ...props.line, vatId: newTva }
                /* setLine(newLine); */
                props.updateOrder(newLine);
                break;
            case 'unit':
                let newUnit = parseInt(e.target.value);
                if(newUnit === null || newUnit === undefined){
                    newUnit = 1;
                }
                newLine = { ...props.line, unitId: newUnit }
                /* setLine(newLine); */
                props.updateOrder(newLine);
                break;
        }
    }

    const changeDesignation = (e) => {
        setDesignation(e.target.value);
    }


    return (
        <div className="line">
            <input type="hidden" name={"idLine" + props.id} />
            <div className="line-component2" style={{ display: "flex", flexDirection: "column" }}>
                <div className="options">
                    <div className="buttons">
                        <p className="num">{props?.numero}</p>
                        <a className="cart" href="#!" onClick={() => { props.openCatalogues(props.index) }}>
                            <img src={require("../../../assets/images/icons/Picto/Achat.svg").default} alt="Ajouter un article" />
                            <p style={{ marginLeft: "3px" }}>Ajouter un article</p>
                        </a>
                        <a className="garbage" href="#!" onClick={() => { props.removeItem(props.index) }}>
                            <img src={require("../../../assets/images/icons/Picto/supprimer.svg").default} alt="Supprimer" />
                        </a>
                    </div>
                    <div className="tri">
                        <a className="up" href="#!" onClick={() => { props.changeOrderLine(props.index, "up") }}>
                            <FontAwesomeIcon icon={faAngleUp} />
                        </a>
                        <a className="down" href="#!" onClick={() => { props.changeOrderLine(props.index, "down") }}>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </a>
                    </div>
                </div>
                <textarea placeholder="Désignation" title="Désignation" name="designation" value={designation} onChange={changeDesignation} onBlur={formatInput}></textarea>
            </div>
            <div className="line-component1">
                <div className="unit">
                    <div>
                        <p>Unité</p>
                        <select name="unit" value={props.line.unitId} onChange={onChange} onBlur={formatInput} >
                            {
                                props.listUnit.map((unit, i) => {
                                    return (
                                        <option key={i} value={unit.id}>{unit.unit_name}</option>
                                    );
                                })
                            }
                        </select>
                    </div>
                    <div className="unit">
                        <p>Quantité</p>
                        <input type="number" placeholder="Quantité" name="quantity" title="Quantité" value={quantity} onChange={onChange} onBlur={formatInput} />
                    </div>
                    <div className="unit">
                        <p>Prix&nbsp;HT</p>
                        <input type="number" placeholder="Prix HT" name="unitPrice" title="Prix HT" value={unitPrice} onChange={onChange} onBlur={formatInput} />
                    </div>
                    <div className="unit">
                        <p>TVA</p>
                        <select name="tva" value={props.line.vatId} onChange={onChange}>
                            {
                                props.listTva.map((tva, i) => {
                                    return (
                                        <option key={i} value={tva.id}>{tva.rate} %</option>
                                    );
                                })
                            }
                        </select>
                    </div>

                </div>
                <div className="options">
                    <div className="line-amount">
                        <p className="ht">MONTANT HT : <span>{parseFloat(props.line.totalHT).toFixed(2)} €</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderLine;
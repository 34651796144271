import "../../../styles/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreditLine from "./creditLine";
import { useCallback, useState } from "react";
import {faAngleDown, faAngleUp, faPlus, faTrashCan} from "@fortawesome/free-solid-svg-icons";

function CreditGroup(props) {
    const [designation, setDesignation] = useState(props?.designation);

    const updateGroup = useCallback((updatedLine, updatedGroup) => {
        let newGroup = { ...props.group };
        let newLines = undefined;
        if (updatedLine !== undefined) {
            newLines = [...newGroup.lines];
            newLines[updatedLine.index] = updatedLine;
        } else {
            newLines = updatedGroup.lines;
        }
        let totalHT = 0;
        newLines.foreach((line) => {
            totalHT = totalHT + parseFloat(line.totalHT);
        });
        let newgroup = undefined;
        if (updatedLine !== undefined) {
            newgroup = {
                ...newGroup,
                totalHT: totalHT,
                index: props.index,
                creditId: props.creditId,
                lines: newLines
            };
        } else {
            newgroup = {
                ...updatedGroup,
                totalHT: totalHT,
                index: props.index,
                creditId: props.creditId,
                lines: newLines
            };
        }
        props.group.lines = newLines;
        props.updateCredit(newgroup, undefined, undefined, undefined);

    }, [props]);

    const onChange = (e) => {
        let value = e.target.value;
        if (e.target.name === "designation") {
            setDesignation(value);
            var newGrp = { ...props.group, title: value };
        }
        updateGroup(undefined, newGrp);
    }

    const addLine = () => {
        let newGroup = { ...props.group }
        let id = generateId();
        newGroup.lines.push(
            {
                id: id,
                name: "",
                unit: "",
                quantity: "1.00",
                unitPrice: "0.00",
                totalHT: "0.00",
                vatId: 8,
                unitId: 1
            }
        );
        props.updateCredit(newGroup);
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        props.group.lines.foreach((line) => {
            while (line.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });
        let newIdHtml = prefixIdLine + newIdLig;
        return newIdHtml;
    }

    function removeItem(index) {
        let newGroup = { ...props.group };
        const filteredLines = props.group?.lines.filter((i) => {
            return i !== index;
        });
        newGroup.lines = filteredLines;
        updateGroup(undefined, newGroup);
    }

    function openCatalogues(index, grpIndex) {
        props.openCatalogues(index, grpIndex);
    }

    function changeOrderLine(index, direction) {
        let newGroup = { ...props.group };
        // console.log(index);
        if (direction === "up") {
            let line = newGroup.lines[index];
            newGroup.lines.splice(index, 1);
            newGroup.lines.splice(index - 1, 0, line);
            // console.log(newGroup.lines);

        } else {
            let line = newGroup.lines[index];
            newGroup.lines.splice(index, 1);
            newGroup.lines.splice(index + 1, 0, line);
        }
        // console.log(newGroup.lines);
        updateGroup(undefined, newGroup);
    }

    return (
        <div className="group">
            <input type="hidden" name={"idGrp" + props.id} id={"idGrp" + props.id} value={props.id} />
            <input type="hidden" name={props.index} />
            <div className="group-header">
                <div>
                    <div>
                        <a className="up" href="#!" onClick={() => { props.changeOrderGroup(props.index, "up") }}>
                            <FontAwesomeIcon icon={faAngleUp} />
                        </a>
                        <a className="down" href="#!" onClick={() => { props.changeOrderGroup(props.index, "down") }}>
                            <FontAwesomeIcon icon={faAngleDown} />
                        </a>
                    </div>
                    <input type="text" placeholder="Désignation groupe" name="designation" value={designation} onChange={onChange} />
                </div>
                <p>TOTAL HT : {parseFloat(props.group.totalHT).toFixed(2)} €</p>
            </div>
            {
                props?.group?.lines?.map((line, i) => (

                    <CreditLine
                        key={i}
                        index={i}
                        line={line}
                        id={line.id}
                        grpId={props.id}
                        grpIndex={props.index}
                        numero={i + 1}
                        designation={line.name}
                        unit={line.unitId}
                        quantity={line.quantity}
                        unitPrice={line.unitPrice}
                        tva={line.vatId}
                        allTva={props.allTva}
                        removeItem={removeItem}
                        listTva={props.listTva}
                        listUnit={props.listUnit}
                        updateGroup={updateGroup}
                        openCatalogues={openCatalogues}
                        changeOrderLine={changeOrderLine}
                        credit={props.credit}
                    />

                ))
            }
            <div className="group-options">
                <div className="new-line" onClick={addLine}>
                    <p>Ajouter une ligne</p>
                    <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className="delete-group" onClick={() => { props.removeGroup(props.index) }}>
                    <FontAwesomeIcon icon={faTrashCan} />
                    <p>Supprimer le groupe</p>
                </div>
            </div>
            <hr />
        </div>
    );
}

export default CreditGroup;

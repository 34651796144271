import axios from "axios";
import { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export default function CreatePersonalItemPop({ setShowCreatePersonalItemPop, itemCopy, personalCatalogList, brandList, familyList, subfamilyList, supplierList, unitList }) {

    const nameRef = useRef();
    const refDistributorRef = useRef();
    const refMakerRef = useRef();
    const packageRef = useRef();
    const basePriceRef = useRef();
    const netPriceRef = useRef();

    const [catalogSelected, setCatalogSelected] = useState(itemCopy?.personalCatalogId);
    const [familySelected, setFamilySelected] = useState(itemCopy?.family);
    const [subfamilySelected, setSubfamilySelected] = useState(itemCopy?.subfamily);
    const [brandSelected, setBrandSelected] = useState(itemCopy?.brand);
    const [supplierSelected, setSupplierSelected] = useState(itemCopy?.supplier);
    const [unitSelected, setUnitSelected] = useState(itemCopy?.auPrice);

    const handleEditSubmit = (e) => {
        e.preventDefault();
        console.log('click');
        const values = {
            id: null,
            cataogueId: catalogSelected ? catalogSelected : itemCopy.personalCatalogId,
            family: familySelected ? familySelected : itemCopy.family,
            subfamily: subfamilySelected ? subfamilySelected : itemCopy.subfamily,
            brand: brandSelected ? brandSelected : itemCopy.brand,
            supplier: supplierSelected ? supplierSelected : itemCopy.supplier,
            name: nameRef.current.value ? nameRef.current.value : itemCopy.designation,
            refMaker: refMakerRef.current.value ? refMakerRef.current.value : itemCopy.refMaker,
            refDistributor: refDistributorRef.current.value ? refDistributorRef.current.value : itemCopy.refDistributor,
            packageItem: packageRef.current.value ? packageRef.current.value : itemCopy.package,
            auPrice: unitSelected ? unitSelected : itemCopy.auPrice,
            netPrice: netPriceRef.current.value ? netPriceRef.current.value : itemCopy.netPrice,
            basePrice: basePriceRef.current.value ? basePriceRef.current.value : itemCopy.basePrice,
        }

        const customHeaders = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        console.log("values", values);

        axios.post(`${process.env.REACT_APP_POST_PERSONAL_ITEM}`, values, customHeaders,)
            .then(response => {
                console.log("response post deposit", response.data);
                // navigate("/depositList")
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <>
            <form className="itemPop" onSubmit={handleEditSubmit} action="submit">
                <div className="form">
                    <div className="header">
                        <h2 className="title">{itemCopy ? "Copier" : "Créer"} un article</h2>
                        <FontAwesomeIcon className="cross" onClick={() => setShowCreatePersonalItemPop(false)} icon={faXmark} />
                    </div>

                    <input className="name" ref={nameRef} defaultValue={itemCopy?.name} type="text" placeholder="name" />
                    <div className="container">
                        <div className="content">

                            <h3>Catégories</h3>

                            <select onChange={(e) => setCatalogSelected(e.target.value)} name="catalog" id="catalog">
                                <option value={itemCopy?.personalCatalogId}>{itemCopy?.personalCatalogId ? itemCopy?.catalog : "Choisir un catalogue"}</option>
                                {
                                    personalCatalogList.map(element => {
                                        console.log();
                                        return (
                                            <option value={element.id}>
                                                {element.name}</option>
                                        )
                                    })
                                }
                            </select>
                            <select onChange={(e) => setBrandSelected(e.target.value)} name="catalog" id="catalog">
                                <option value={itemCopy?.brand}>{itemCopy?.brand ? itemCopy?.brand : "Choisir une marque"}</option>
                                {
                                    brandList.map(element => {
                                        console.log();
                                        return (
                                            <option value={element.brand}>
                                                {element.brand}</option>
                                        )
                                    })
                                }
                            </select>
                            <select onChange={(e) => setFamilySelected(e.target.value)} name="catalog" id="catalog">
                                <option value={itemCopy?.family}>{itemCopy?.family ? itemCopy?.family : "Choisir une famille"}</option>
                                {
                                    familyList.map(element => {
                                        console.log();
                                        return (
                                            <option value={element.family}>
                                                {element.family}</option>
                                        )
                                    })
                                }
                            </select>
                            <select onChange={(e) => setSubfamilySelected(e.target.value)} name="catalog" id="catalog">
                                <option value={itemCopy?.subfamily}>{itemCopy?.subfamily ? itemCopy?.subfamily : "Choisir une sous famille"}</option>
                                {
                                    subfamilyList.map(element => {
                                        console.log();
                                        return (
                                            <option value={element.subfamily}>
                                                {element.subfamily}</option>
                                        )
                                    })
                                }
                            </select>
                            <select onChange={(e) => setSupplierSelected(e.target.value)} name="catalog" id="catalog">
                                <option value={itemCopy?.supplier}>{itemCopy?.supplier ? itemCopy?.supplier : "Choisir une fournisseur"}</option>
                                {
                                    supplierList.map(element => {
                                        console.log();
                                        return (
                                            <option value={element.supplier}>
                                                {element.supplier}</option>
                                        )
                                    })
                                }
                            </select>
                            <select onChange={(e) => setUnitSelected(e.target.value)} name="catalog" id="catalog">
                                <option value={itemCopy?.auPrice}>{itemCopy?.auPrice ? itemCopy?.auPrice : "Choisir une unitée"}</option>
                                {
                                    unitList.map(element => {
                                        console.log();
                                        return (
                                            <option value={element.unit_id}>
                                                {element.unit_name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="content">

                            <input ref={refDistributorRef} defaultValue={itemCopy?.refDistributor} type="text" placeholder="ref distributeur" />
                            <input ref={refMakerRef} defaultValue={itemCopy?.refMaker} type="text" placeholder="ref fabriquant" />
                            <input ref={packageRef} defaultValue={itemCopy?.package} type="text" placeholder="Conditionnement" />
                        </div>
                        <div className="content">

                            <input ref={netPriceRef} defaultValue={itemCopy?.netPrice} type="text" placeholder="Prix d'achat" />
                            <input ref={basePriceRef} defaultValue={itemCopy?.basePrice} type="text" placeholder="Prix de vente" />
                        </div>


                    </div>
                    <button className="button">Confirmer</button>
                </div>
            </form>
        </>
    )
}
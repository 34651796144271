import SelectCatalog from './SelectCatalog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
export default function EditCatalog({ handleEditCatalog, catalogNameRef, handleSelectedCatalog, personalCatalogList }) {
    return (
        <>
        <div className='formDiv'>
            <FontAwesomeIcon className='button' onClick={handleEditCatalog} icon={faCheck} />
            <SelectCatalog handleSelectedCatalog={handleSelectedCatalog} personalCatalogList={personalCatalogList} />
            <input ref={catalogNameRef} type="text" placeholder='Nouveau nom ...' />
        </div>
        </>
    )
}
export const stateTransition = [
    {
        name: 'draft',
        translate: "Brouillon",
        color: 'Blue'
    },
    {
        name: 'signed',
        translate: "Signé",
        color: 'Orange'
    },
    {
        name: 'validated',
        translate: "Finalisé",
        color: 'Green'
    },
    {
        name: 'canceled',
        translate: "Annulé",
        color: 'Red'
    },
    {
        name: 'transfer',
        translate: "Transférer",
        color: 'Purple'
    },
    {
        name: 'in-progress',
        translate: "En cours",
        color: 'Orange'
    }
];
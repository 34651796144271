import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useCallback, useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CalendarPicker from "../../components/addOns/Calendar";
import CataloguesListPop from "../../components/customer/catalogues/CataloguesListPop";
import ContactsListPop from "../../components/customer/contacts/ContactsListPop";
import MailPop from "../../components/addOns/MailPop";
import OrderLine from "../../components/customer/order/OrderLine";
import { AppContext } from "../../context/appContext";
import {
    faAngleUp,
    faCalendarDays,
    faCopy,
    faDownload,
    faGear,
    faPlus,
    faShuffle,
    faTrashCan
} from "@fortawesome/free-solid-svg-icons";

function Order() {
    let { id } = useParams();
    let navigate = useNavigate();
    const appContext = useContext(AppContext);

    const [order, setOrder] = useState({});
    const [listTva, setListTva] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [interlocutors, setInterlocutors] = useState();
    const [ref, setRef] = useState();
    const [dateEmission, setDateEmission] = useState(new Date());
    const [dateValidation, setDateValidation] = useState(new Date());
    const [subject, setSubject] = useState();
    const [subTotalHT, setSubTotalHT] = useState(0);
    const [totalHT, setTotalHT] = useState({});
    const [totalTTC, setTotalTTC] = useState({});
    const [contact, setContact] = useState();
    const [discount, setDiscount] = useState();
    const [discountPrice, setDiscountPrice] = useState();
    const [comment, setComment] = useState();
    const [catalogues] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [showCalendarValidation, setShowCalendarValidation] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showMail, setShowMail] = useState(false);
    const [firstShowModal, setFirstShowModal] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [showCatalogues, setShowCatalogues] = useState(false);
    const [lineSelected, setLineSelected] = useState();
    const [lineIndexSelected, setLineIndexSelected] = useState();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ORDER_GET}${id}`)
            .then(response => {
                // console.log(response.data);
                setOrder(response.data?.order);
                setListTva(response.data?.listTva);
                setListUnit(response.data?.listUnit);
                setInterlocutors(response.data?.listInterlocutors);
                setRef(response.data.order?.ref);
                // console.log("response.data.order?.ref", response.data.order?.ref);
                setDiscount(response.data.order?.discount);


                setSubject(response.data.order?.subject);
                // console.log("response.data.credit.subject", response.data.order?.subject);
                setTotalHT(response.data.order?.totalHT);
                setTotalTTC(response.data.order?.totalTTC);
                setContact(response.data.order?.contact)
                setComment(response.data.order?.comment);
                setContact(response.data.order?.contact);
                if (response.data.order?.orderDate === undefined) {
                    setDateEmission(new Date());
                } else {
                    let dayEmission = response.data.order?.orderDate.split("-")[1];
                    let monthEmission = response.data.order?.orderDate.split("-")[2];
                    let yearEmission = response.data.order?.orderDate.split("-")[0];
                    let newDateEmission = dayEmission + "/" + monthEmission + "/" + yearEmission;
                    setDateEmission(new Date(newDateEmission));
                }
                if (response.data.order?.paymentDate !== null) {
                    let dayValidation = response.data.order?.paymentDate.split("-")[1];
                    let monthValidation = response.data.order?.paymentDate.split("-")[2];
                    let yearValidation = response.data.order?.paymentDate.split("-")[0];
                    let newDateValidation = dayValidation + "/" + monthValidation + "/" + yearValidation;
                    setDateValidation(new Date(newDateValidation));
                } else {
                    setDateValidation(null);
                }


            })
            .catch(error => {
                console.log("err GET order.js", error);
            })

    })


    const onValidOrder = async (e) => {
        e.preventDefault();
        const customHeaders = {
            'content-type': 'application/json',
        };


        const values = {
            reference: ref,
            destinataire: contact?.id,
            date_emission: dateEmission?.toLocaleDateString(),
            date_validation: dateValidation?.toLocaleDateString(),
            titre: subject,
            interlocuteur: order.userId,
            Hremise1: discount,
            commentaire: comment,
            Htotalht: totalHT,
            Htotalttc: totalTTC,
            lines: order.lines,
        };

        /*  order.lines.map((line, i) => {
             values['numero_' + line.id] = i
             values['designation_' + line.id] = line.name
             values['idLine_' + line.id] = line.id
             values['Hquant_' + line.id] = line.quantity
             values['unite_' + line.id] = line.unitId
             values['Hprix_' + line.id] = line.unitPrice
             values['tauxtva_' + line.id] = line.vatId
             
         }); */


        await axios.post(`${process.env.REACT_APP_ORDER_POST}${id}`, values, customHeaders)
            .then(response => {
                console.log('values ok', values);
                console.log('data order POST', response.data);
                navigate("/orderList");
            })
            .catch(error => {
                console.log('values err', values);
                console.log(error);
            })
    }


    const handleClickCalendarValidation = () => {
        if (showCalendarValidation) {
            setShowCalendarValidation(false);
        } else {
            setShowCalendarValidation(true)
        }
    }

    const updateOrder = useCallback((updateLine = undefined, newDiscount = undefined, updateOrder = undefined) => {
        setOrder(order => {
            let newOrder;
            if (updateOrder !== undefined) {
                newOrder = { ...updateOrder };
            } else {
                newOrder = { ...order };
            }

            const newLine = [...newOrder.lines];
            if (updateLine !== undefined) {
                newLine[updateLine.index] = updateLine;
                newOrder = { ...newOrder, lines: newLine };
            }

            let subtotalHT = 0;
            newLine.map((line) => {
                return subtotalHT = subtotalHT + parseFloat(line.totalHT);
            });
            setSubTotalHT(subtotalHT);

            let newdiscount = 0;
            let newdiscountPrice = 0;
            if (newDiscount !== undefined) {
                newdiscount = newDiscount;
                setDiscount(newdiscount);
                newdiscountPrice = subtotalHT * (parseFloat(newDiscount) / 100);
            } else {
                newdiscount = discount;
                if (newdiscount !== undefined) {
                    newdiscountPrice = subtotalHT * (newdiscount / 100);
                } else {
                    newdiscountPrice = 0;
                }
            }
            setDiscountPrice(newdiscountPrice);
            let totalht = subtotalHT.toFixed(2) - newdiscountPrice.toFixed(2);
            setTotalHT(totalht);

            let newListTva = [...listTva];

            for (let i = 0; i < newListTva.length; i++) {
                newListTva[i].value = 0;
            }
            newOrder.lines.foreach((line) => {
                for (let j = 0; j < newListTva.length; j++) {
                    if (newListTva[j].id === line.vatId) {
                        let lineTva = ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) - ((parseFloat(line.quantity) * parseFloat(line.unitPrice)) * (newdiscount / 100))) * newListTva[j].rate / 100;
                        newListTva[j].value = newListTva[j].value + lineTva;
                    }
                }
            });
            setListTva(newListTva);

            let amountTotalTva = 0;
            for (let i = 0; i < newListTva.length; i++) {
                amountTotalTva += newListTva[i].value;
            }
            let totalttc = parseFloat(totalht.toFixed(2)) + parseFloat(amountTotalTva.toFixed(2));
            setTotalTTC(totalttc);
            return newOrder;
        })
    }, [listTva, discount]);

    const onChangeInput = (e) => {
        let value = e.target.value;
        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (e.target.name === "discount") {
            if (validated) {
                setDiscount(value);
            } else {
                value = 0;
                setDiscount(value);
            }
            updateOrder(null, value, null);
        }
    }

    const formatInput = (e) => {
        if (e.target.name === "discount") {
            let newDiscount = e.target.value;
            newDiscount = parseFloat(parseFloat(newDiscount.replace(',', '.')).toFixed(2));
            if (isNaN(newDiscount)) {
                newDiscount = "0.00";
            } else {
                newDiscount = parseFloat(newDiscount).toFixed(2);
            }
            setDiscount(newDiscount);
        }
        updateOrder();
    }

    function onChangeDate(newDate) {
        setDateEmission(newDate);
    }

    function onChangeDateValidation(newDate) {
        setDateValidation(newDate);
    }

    const handleClickModal = () => {
        appContext.setTest(true)
        if (firstShowModal === false) {
            axios.get(`${process.env.REACT_APP_ORDER_CONTACT_LIST}`)
                .then(response => {
                    setContacts(response.data.listContacts);
                })
            setFirstShowModal(true);
        }
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    function handleSelectedContact(index) {
        console.log(index);
        contacts.forEach(element => {
            if (element.id === index) {
                setContact(element);
            }
        });
        closeModal();
    }

    function openCatalogues(index) {
        setShowCatalogues(true);
        setLineIndexSelected(index);
        setLineSelected(order.lines[index]);
    }

    function handleCloseCatalogues() {
        setShowCatalogues(false);
    }

    function handleSelectedArticle(article) {
        let item = article;
        setShowCatalogues(false);
        setOrder(order => {
            let newOrder = { ...order };
            let newLine = { ...lineSelected };
            newLine.name = item.name;
            newLine.quantity = 1;
            newLine.unitPrice = item.basePrice;
            newOrder.lines[lineIndexSelected] = newLine;
            return newOrder;
        });
    }

    const onChangeInterlocutor = (e) => {
        let newInterlocutors = e.target.value;
        let newOrder = { ...order, userId: newInterlocutors };
        setOrder(newOrder);
    }

    const addLine = () => {
        let newOrder = { ...order };
        let id = generateId();
        newOrder.lines.push(
            {
                id: id,
                name: "",
                unit: "",
                quantity: "1.00",
                unitPrice: "0.00",
                totalHT: "0.00",
                vatId: 8,
                unitId: 1
            }
        );
        setOrder(newOrder);
        updateOrder();
    }

    function removeItem(index) {
        let newOrder = { ...order };
        const filteredLines = order.lines.filter((line, i) => {
            return i !== index;
        });
        newOrder.lines = filteredLines;
        setOrder(newOrder);
        updateOrder();
    }

    function changeOrderLine(index, direction) {
        let newOrder = { ...order };
        if (direction === "up") {
            let line = newOrder.lines[index];
            newOrder.lines.splice(index, 1);
            newOrder.lines.splice(index - 1, 0, line);
        } else {
            let line = newOrder.lines[index];
            newOrder.lines.splice(index, 1);
            newOrder.lines.splice(index + 1, 0, line);
        }

        updateOrder(undefined, undefined, newOrder);
    }

    const generateId = () => {
        let prefixIdLine = "New";
        let newIdLig = 0;

        order.lines.foreach((line) => {
            while (line.id === prefixIdLine + newIdLig) {
                newIdLig += 1;
            }
        });
        return prefixIdLine + newIdLig;
    }

    const printOrder = () => {
        window.open(`${process.env.REACT_APP_ORDER_PRINT}${order.id}`, "_blank");
    }

    function closeMailPop() {
        setShowMail(false);
    }

    function duplicate() {
        window.open(`${process.env.REACT_APP_ORDER_DUP_GET}${order.id}`, '_self');
    }

    function deleteOrder() {
        axios.post(`${process.env.REACT_APP_ORDER_DELETE}${order.id}`)
            .then(() => {
                navigate("/orderList");
            })
            .catch(error => {
                console.log("err delet order", error);
            })
    }
    const handleClickCalendar = () => {
        if (showCalendar === true) {

            setShowCalendar(false);
        } else {
            setShowCalendar(true);
        }
        document.getElementById('abso').classList.add("abso")
    }

    const closePopup = () => {
        setShowCalendar(false);
        document.getElementById('abso').classList.remove("abso")
    };

    return (
        <>
            {/* <TabBar
                elem='expenses'
            /> */}
            <ContactsListPop
                show={showModal}
                handleClose={closeModal}
                data={contacts}
                handleSelectedContact={handleSelectedContact}
            />
            <CataloguesListPop
                show={showCatalogues}
                handleCloseCatalogues={handleCloseCatalogues}
                handleSelectedArticle={handleSelectedArticle}
                data={catalogues}
            />
            <div className="main">
                <MailPop
                    show={showMail}
                    orderId={order?.id}
                    closeMailPop={closeMailPop}
                />
                <ContactsListPop />
                <form onSubmit={(values) => onValidOrder(values)}>
                    <div className="contract">
                        <div id='abso' onClick={closePopup} className='abso'></div>
                        <div className="toolbar">
                            <div className="back-icon">
                                <Link to="/orderList">
                                    <img src={require("../../assets/images/icons/Picto/Retour.svg").default} alt="retour en arrière" />
                                </Link>
                            </div>
                            <div className="header">
                                <div className="ref">
                                    <div className="num">
                                        <h2>DEPENSE&nbsp;N° </h2>
                                        <input type="text" name="ref" value={ref} onChange={(e) => setRef(e.target.value)} />
                                    </div>
                                    <div className="date" id="date">
                                        {/* {
                                            (dateEmission) && (
                                                <CalendarPicker
                                                    onChangeDate={onChangeDate}
                                                    value={dateEmission}
                                                    show={showCalendar}
                                                />
                                            )
                                        } */}
                                        {dateEmission && showCalendar && (
                                            <CalendarPicker
                                                onChangeDate={onChangeDate}
                                                value={dateEmission}
                                                show={showCalendar}
                                            />
                                        )}
                                        <a href="#!" onClick={handleClickCalendar}><FontAwesomeIcon icon={faCalendarDays} /></a>
                                        <p>{dateEmission?.toLocaleDateString("fr-FR")}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tools">
                                <button className="save" type="submit">
                                    <p>Sauvegarder</p>
                                    <img src={require("../../assets/images/icons/Picto/Sauvegarder_white.svg").default} alt="Sauvegarder" />
                                </button>
                                <div className="dropdown">
                                    <a href="#!" className="dropdown-toggle">
                                        <p>Plus d'actions</p>
                                        <FontAwesomeIcon icon={faGear} />
                                        <FontAwesomeIcon icon={faAngleUp} style={{ marginLeft: "8px" }} />
                                    </a>
                                    <div className="dropdown-menu">
                                        <a href="#!" onClick={printOrder}>
                                            <FontAwesomeIcon icon={faDownload} />
                                            <p>Télécharger</p>
                                        </a>
                                        <a href="#!" onClick={() => setShowMail(true)}>
                                            {/*<FontAwesomeIcon icon={fa}/>*/}
                                            <p>Envoyer</p>
                                        </a>
                                        <a href="#!" onClick={() => duplicate()}>
                                            <FontAwesomeIcon icon={faCopy} />
                                            <p>Dupliquer</p>
                                        </a>
                                        <a href="#!" >
                                            <FontAwesomeIcon icon={faShuffle} />
                                            <p>Transférer</p>
                                        </a>
                                    </div>
                                </div>
                                <a className="garbage" href="#!" onClick={() => deleteOrder()}><FontAwesomeIcon icon={faTrashCan} /></a>
                                <hr />
                            </div>
                        </div>
                        <div className="block">
                            <div className="infos">
                                <h3>INFORMATIONS</h3>
                                <div className="infos-block">
                                    <div className="subject">
                                        <h4>Intitulé de la dépense</h4>
                                        <input name="subject" placeholder="EX : DEPENSE POUR UN OUTILS" value={subject} onChange={(e) => setSubject(e.target.value)} />
                                    </div>
                                    <div className="interlocutor">
                                        <div>
                                            <h4>Affaire suivie par</h4>
                                        </div>
                                        <select name="destinataire" value={order?.userId} onChange={onChangeInterlocutor}>
                                            {interlocutors?.map((interlocutor, i) => (
                                                <option key={i} value={interlocutor.id}>{interlocutor.first_name} {interlocutor.last_name}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                    <div className="contact">
                                        <div>
                                            <h4>Client</h4>
                                        </div>
                                        <p>{contact?.lastName} {contact?.firstName} {(((contact?.lastName !== undefined && contact?.lastName !== null) || (contact?.firstName !== undefined && contact?.firstName !== null)) && (contact?.society !== undefined && contact?.society !== null)) ? `-` : ""} {contact?.society}</p>
                                    </div>
                                    <div className="contact-search">
                                        <a href="#!" onClick={handleClickModal}>Rechercher un client<img src={require("../../assets/images/icons/Picto/search.svg").default} alt="rechercher" /></a>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div className="contract-body">
                                <div className="container">
                                    <div className="group">
                                        {order?.lines && order?.lines.map((line, i) => {
                                            return (
                                                <OrderLine
                                                    key={i}
                                                    index={i}
                                                    numero={i + 1}
                                                    line={line}
                                                    id={line.id}
                                                    designation={line.name}
                                                    listTva={listTva}
                                                    listUnit={listUnit}
                                                    quantity={line.quantity}
                                                    unitPrice={line.unitPrice}
                                                    updateOrder={updateOrder}
                                                    openCatalogues={openCatalogues}
                                                    removeItem={removeItem}
                                                    changeOrderLine={changeOrderLine}
                                                />
                                            )
                                        })
                                        }

                                    </div>
                                    <div className="new-group" onClick={addLine}>
                                        <p>Ajouter une nouvelle ligne</p>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </div>
                                    <div className="price">
                                        <div className="bold-block sub-total-ht">
                                            <p>Sous total HT</p>
                                            <p>{parseFloat(subTotalHT).toFixed(2)} €</p>
                                        </div>
                                        <div className="discount">
                                            <div>
                                                <p>Remise Globale</p>
                                                <input type="text" placeholder="0,00" name="discount" value={discount} onChange={onChangeInput} onBlur={formatInput} /> %
                                            </div>
                                            <p>{parseFloat(discountPrice).toFixed(2)} €</p>
                                        </div>
                                        <div className="bold-block total-ht">
                                            <p>Total HT</p>
                                            <p>{parseFloat(totalHT).toFixed(2)} €</p>
                                        </div>
                                        <div className="tva">
                                            {
                                                listTva &&
                                                listTva.map((tva, i) => {
                                                    if (tva.id !== 8) {
                                                        return (
                                                            <div key={i}>
                                                                <p>TVA à {tva.rate}</p>
                                                                <p>{parseFloat(tva.value).toFixed(2)} €</p>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            }
                                        </div>
                                        <div className="bold-block total-ttc">
                                            <p>Total net TTC</p>
                                            <p>{parseFloat(totalTTC).toFixed(2)} €</p>
                                        </div>
                                    </div>
                                    <div className="comment">
                                        <h3>COMMENTAIRE AJOUTE À LA DÉPENSE</h3>
                                        <textarea placeholder="Description" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                    </div>

                                    <div className="dateDelivery">
                                        <h3>SUIVI DE RÈGLEMENT</h3>

                                        <div className="datePayment">
                                            {/* 
                                                TODO: - séparer les fonctions par date en fonction (dateEmission et dateValidation)
                                                - mettre du css sur le bloc en reprenant le style de la date d'emission
                                                - deplacer l'affichage du calendrier, le mettre en bas et en flex et pas en absolute 
                                            */}
                                            <h4>Date de livraison :</h4>
                                            {
                                                (
                                                    <CalendarPicker
                                                        onChangeDate={onChangeDateValidation}
                                                        value={dateValidation}
                                                        show={showCalendarValidation}
                                                    />
                                                )
                                            }
                                            <Link to="/" onClick={handleClickCalendarValidation}><FontAwesomeIcon icon={faCalendarDays} /></Link>
                                            <p>{dateValidation?.toLocaleDateString("fr-FR")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </form>
            </div>

        </>
    )

}

export default Order;
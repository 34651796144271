import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrderCard from "../../components/customer/order/OrderCard";
import Spinner from '../../components/addOns/Spinner';
import OrderFilter from "../../components/customer/order/orderFilter";
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import {faArrowUp, faMagnifyingGlass, faPlus} from "@fortawesome/free-solid-svg-icons";


function OrderList() {

    const [orderList, setOrderList] = useState();
    const [interlocutors] = useState();
    const [orderState, setOrderState] = useState('all');
    const [loading, setLoading] = useState(true);
    const [showFilter, setShowFilter] = useState(false);
    const crossRotate = showFilter ? "cross-rotate" : "cross-reverse";
    const [sort, setSort] = useState('datedown')
    const [lastname, setLastname] = useState();
    const [firstname, setFirstname] = useState();
    const [orderRef, setOrderRef] = useState();
    const [interloSearch, setInterloSearch] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [society, setSociety] = useState();
    const [setOrdersList] = useState();

    const [nbPages, setNbPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ORDER_LIST}&state=${orderState}&sort=${sort}&page=${currentPage}&nombre=${perPage}`)
            .then(response => {
                console.log(response.data);
                setOrderList(response.data.orderFormsList);
                setLoading(false)
                setNbPages(response.data.nbPages);
                setPerPage(response.data.perPage);
                setTotalTTC(response.data.totalAmonts.total_ttc);
                setTotalHT(response.data.totalAmonts.total_ht);
                setTotalVAT(response.data.totalAmonts.total_vat);
                if (response.data.nbPages < response.data.currentPage) {
                    setCurrentPage(response.data.nbPages);
                } else {
                    setCurrentPage(response.data.currentPage);
                }
            })
            .catch(error => {
                console.log(error);
            })

        let all = document.getElementById('all')
        let valid = document.getElementById('valid')
        let unvalid = document.getElementById('unvalid')
        let draft = document.getElementById('draft')
        let canceled = document.getElementById('canceled')

        all.classList.remove('active')
        valid.classList.remove('active')
        unvalid.classList.remove('active')
        draft.classList.remove('active')
        canceled.classList.remove('active')

        switch (orderState) {
            case 'all':
                all.classList.add('active')
                break;

            case 'valid':
                valid.classList.add('active')
                break;

            case 'unvalid':
                unvalid.classList.add('active')
                break;

            case 'draft':
                draft.classList.add('active')
                break;

            case 'canceled':
                canceled.classList.add('active')
                break;

            default:
                break;
        }
    }, [orderState, sort, currentPage, perPage]);





    const ordersFilter = () => {
        if (!showFilter) {
            setShowFilter(true);
        } else {
            setShowFilter(false);
        }
    }
    const changeValueFilter = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);

        switch (e.target.name) {
            case 'lastName':
                setLastname(e.target.value)
                break;
            case 'firstName':
                setFirstname(e.target.value)
                break;
            case 'orderttRef':
                setOrderRef(e.target.value)
                break;
            case 'interloSearch':
                setInterloSearch(e.target.value)
                break;
            case 'startDate':
                setStartDate(e.target.value)
                break;
            case 'endDate':
                setEndDate(e.target.value)
                break;
            case 'society':
                setSociety(e.target.value)
                break;

            default:
                break;
        }

    }

    const onValidFilter = async (e) => {
        e.preventDefault();

        setOrdersList(null)
        setLoading(true)

        // console.log(lastname);

        const customHeaders = {
            'content-type': 'application/json',
        };

        const values = {
            lastName: lastname,
            firstName: firstname,
            society: society,
            interlocuteur: interloSearch,
            startDate: startDate,
            endDate: endDate,
            orderRef: orderRef
        }

        await axios.post(`${process.env.REACT_APP_ORDER_FILTER_POST}&sort=${sort}&state=${orderState}&society=${society}`, values, customHeaders)
            .then(response => {
                // console.log(response.data);
                setOrdersList(response.data.ordersList)
                setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })

    }

    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };


    return (
        <>
            {/* <TabBar
                elem='expenses'
            /> */}
                {loading &&
                    (
                        <Spinner />
                    )
                }
                <div className="list">
                    <div className="toolbar">
                        <div className="title">
                            <h2>VOS <span>DEPENSES</span></h2>
                        </div>
                        <div className="button">
                            <Link to="/order">
                                <FontAwesomeIcon icon={faPlus} /><span>Nouvelle dépense</span></Link>
                        </div>
                    </div>
                    <div className="type">
                        <div className="all active" id='all'>
                            <h3 onClick={() => { setOrderState('all'); setOrderList(null); setLoading(true) }} >TOUS LES DEPENSES</h3>
                        </div>
                        <div className="all active" id='valid'>
                            <h3 onClick={() => { setOrderState('valid'); setOrderList(null); setLoading(true) }} >VALIDÉ</h3>
                        </div>
                        <div className="signed" id='unvalid'>
                            <h3 onClick={() => { setOrderState('unvalid'); setOrderList(null); setLoading(true) }} >NON VALIDÉ</h3>
                        </div>
                        <div className="validated" id='draft'>
                            <h3 onClick={() => { setOrderState('draft'); setOrderList(null); setLoading(true) }} >BROUILLON</h3>
                        </div>
                        <div className="draft" id='canceled'>
                            <h3 onClick={() => { setOrderState('canceled'); setOrderList(null); setLoading(true) }} >ANNULÉ</h3>
                        </div>

                    </div>

                    <div className="search-bar" >

                        <p onClick={ordersFilter}><span><FontAwesomeIcon icon={faMagnifyingGlass} />RECHERCHER UN AVOIR</span><FontAwesomeIcon className={crossRotate} icon={faPlus} viewBox="0 0 350 350" /></p>
                        <OrderFilter
                            showFilter={showFilter}
                            interlocutors={interlocutors}
                            onValidFilter={onValidFilter}
                            changeValueFilter={changeValueFilter}
                        />
                    </div>
                    <div style={{ justifyContent: "start" }} className="filter">
                        <div className="filter-component">
                            <div>
                                <p>Trier par :</p>
                            </div>
                            <div>
                                <select onChange={(e) => { setSort(e.target.value); setOrderList(null); setLoading(true) }} >
                                    <option value="dateup">Date de création croissant<FontAwesomeIcon icon={faArrowUp} /></option>
                                    <option value="datedown">Date de création décroissant<FontAwesomeIcon icon={faArrowUp} /></option>
                                    <option value="refup">Références croissant</option>
                                    <option value="refdown">Références décroissant</option>
                                    <option value="follow">Suivi par</option>
                                    <option value="object">Objet</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="cards-list">
                        {orderList && orderList.map((order, k) => {
                            return (
                                <OrderCard
                                    key={k}
                                    orderId={order.order_form_id}
                                    refOrder={order.ref}
                                    totalHT={order.total_ht}
                                    totalTTC={order.total_ttc}
                                    orderDate={order.order_date}
                                    contactId={order.societyContactId}
                                    contactLastName={order.LastName}
                                    contactFirstName={order.FirstName}
                                    societyContact={order.society}
                                    state={order.state}
                                />
                            )
                        })
                        }


                    </div>
                </div>
                <TotalAmonts
                    totalTTC={totalTTC}
                    totalHT={totalHT}
                    totalVAT={totalVAT}
                />                
                <div className='pagination'>
                    <label htmlFor='perPage'>Éléments par page </label>
                    <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <Pagination
                        postsPerPage={perPage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        nbPages={nbPages}
                    />
                </div>
                <a href="#!" alt="" className="add-button"><i className="fa-solid fa-plus"></i></a>
    
        </>
    );
}

export default OrderList;
import {useEffect, useState} from "react";

export default function BasicInput({placeholder, className, refs, key, name, type, id, labelName, autoComplete}) {

    const [inputWidth, setInputWidth] = useState('auto');

    useEffect(() => {
        const span = document.createElement('span');
        span.style.visibility = 'hidden';
        span.style.whiteSpace = 'nowrap';
        span.style.fontSize = window.getComputedStyle(refs?.current).fontSize;
        span.style.fontFamily = window.getComputedStyle(refs?.current).fontFamily;
        span.textContent = placeholder;

        document.body.appendChild(span);
        setInputWidth(`${span.offsetWidth}px`);
        document.body.removeChild(span);
    }, [placeholder, refs]);

    return (
        <>
            <div className={`basicInputContainer ${className}`}>
                {labelName &&
                    <label className="basicInputContainer__label" htmlFor={id}>{labelName}</label>
                }
                <input autoComplete={autoComplete} id={id} style={{width: inputWidth}} key={key} name={name} type={type} placeholder={placeholder}
                       ref={refs} className="basicInputContainer__button" />
            </div>
        </>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import CreditCard from '../../components/customer/credits/creditCard';
import axios from 'axios';
import { useEffect, useState } from 'react';
// import Spinner from '../../addOns/Spinner';
import CreditFilter from '../../components/customer/credits/creditFilter';
import Pagination from '../../components/addOns/Pagination';
import TotalAmonts from '../../components/addOns/TotalAmonts';
import {faArrowUp, faMagnifyingGlass, faPlus} from "@fortawesome/free-solid-svg-icons";

function CreditList() {

    const [sort, setSort] = useState('datedown')
    const [showFilter, setShowFilter] = useState(false);
    const crossRotate = showFilter ? "cross-rotate" : "cross-reverse";
    const [interlocutors] = useState();
    // const [loading, setLoading] = useState(true);
    const [creditsList, setCreditsList] = useState();
    const [lastname, setLastname] = useState();
    const [firstname, setFirstname] = useState();
    const [creditRef, setCreditRef] = useState();
    const [interloSearch, setInterloSearch] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [society, setSociety] = useState();
    const [creditState, setCreditState] = useState('all');

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState();
    const [nbPages, setNbPages] = useState();
    const [totalTTC, setTotalTTC] = useState(0);
    const [totalHT, setTotalHT] = useState(0);
    const [totalVAT, setTotalVAT] = useState(0);


    useEffect(() => {
        // console.log("log sort :", sort);
        axios.get(`${process.env.REACT_APP_CREDIT_LIST}&state=${creditState}&sort=${sort}&currentPage=${currentPage}&parPage=${perPage}`)
            .then(response => {
                setCreditsList(response.data.creditsList);
                setPerPage(response.data.perPage);
                setNbPages(response.data.nbPages);
                setTotalTTC(response.data.totalAmonts.total_ttc);
                setTotalHT(response.data.totalAmonts.total_ht);
                setTotalVAT(response.data.totalAmonts.total_vat);
                if (response.data.nbPages < response.data.currentPage) {
                    setCurrentPage(response.data.nbPages);
                } else {
                    setCurrentPage(response.data.currentPage);
                }

                // setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })



        let all = document.getElementById('all')
        let paid = document.getElementById('paid')
        let unpaid = document.getElementById('unpaid')
        let draft = document.getElementById('draft')
        let canceled = document.getElementById('canceled')

        all.classList.remove('active')
        paid.classList.remove('active')
        unpaid.classList.remove('active')
        draft.classList.remove('active')
        canceled.classList.remove('active')

        switch (creditState) {
            case 'all':
                all.classList.add('active')
                break;

            case 'paid':
                paid.classList.add('active')
                break;

            case 'unpaid':
                unpaid.classList.add('active')
                break;

            case 'draft':
                draft.classList.add('active')
                break;

            case 'canceled':
                canceled.classList.add('active')
                break;

            default:
                break;
        }
    }, [creditState, sort, currentPage, perPage]);

    const creditsFilter = () => {
        if (!showFilter) {
            setShowFilter(true);
        } else {
            setShowFilter(false);
        }
    }
    const changeValueFilter = (e) => {
        console.log("log de value", e.target.value);

        switch (e.target.name) {
            case 'lastName':
                setLastname(e.target.value)
                break;
            case 'firstName':
                setFirstname(e.target.value)
                break;
            case 'creditRef':
                setCreditRef(e.target.value)
                break;
            case 'interloSearch':
                setInterloSearch(e.target.value)
                break;
            case 'startDate':
                setStartDate(e.target.value)
                break;
            case 'endDate':
                setEndDate(e.target.value)
                break;
            case 'society':
                setSociety(e.target.value)
                break;

            default:
                break;
        }

    }
    // console.log("log de creditsList avant", creditsList);
    const onValidFilter = async (e) => {
        e.preventDefault();

        // setLoading(true)
        setCreditsList(null)
        // console.log("log de creditsList apres", creditsList);

        // console.log('log de last name', lastname);

        const customHeaders = {
            'content-type': 'application/json',
        };

        const values = {
            lastName: lastname,
            firstName: firstname,
            society: society,
            interlocuteur: interloSearch,
            startDate: startDate,
            endDate: endDate,
            creditRef: creditRef
        }

        await axios.post(`${process.env.REACT_APP_CREDIT_FILTER_POST}&sort=${sort}&state=${creditState}&society=${society}`, values, customHeaders)
            .then(response => {
                console.log("post liste", response.data);
                setCreditsList(response.data.creditsList)
                // setLoading(false)
            })
            .catch(error => {
                console.log(error);
            })

    }

    const changePerPage = (e) => {
        setPerPage(e.target.value);
        setCurrentPage(1);
    };
    return (
        <>
            {/* <TabBar
                elem='credits'
            /> */}
                {/*loading &&
                    (
                        <Spinner />
                    )
                    */}
                <div className='listPage'>
                    <div className="toolbar">
                        <div className="title">
                            <h2>VOS <span>AVOIRS</span></h2>
                        </div>
                        <div className="button">
                            <Link to="/credit">
                                <FontAwesomeIcon icon={faPlus} /><span>Nouvel avoir</span></Link>
                        </div>
                    </div>
                    <div className="type">
                        <div className="all active" id='all'>
                            <h3 onClick={() => { setCreditState('all'); setCreditsList(null); /*setLoading(true)*/ }} >TOUS LES AVOIRS</h3>
                        </div>
                        <div className="all active" id='paid'>
                            <h3 onClick={() => { setCreditState('paid'); setCreditsList(null); /*setLoading(true)*/ }} >PAYÉ</h3>
                        </div>
                        <div className="signed" id='unpaid'>
                            <h3 onClick={() => { setCreditState('unpaid'); setCreditsList(null); /*setLoading(true)*/ }} >IMPAYÉ</h3>
                        </div>
                        <div className="validated" id='draft'>
                            <h3 onClick={() => { setCreditState('draft'); setCreditsList(null); /*setLoading(true)*/ }} >BROUILLON</h3>
                        </div>
                        <div className="draft" id='canceled'>
                            <h3 onClick={() => { setCreditState('canceled'); setCreditsList(null); /*setLoading(true)*/ }} >ANNULÉ</h3>
                        </div>

                    </div>
                    <div className="search-bar" >

                        <p onClick={creditsFilter}><span><FontAwesomeIcon icon={faMagnifyingGlass} />RECHERCHER UN AVOIR</span><FontAwesomeIcon className={crossRotate} icon={faPlus} viewBox="0 0 350 350" /></p>
                        <CreditFilter
                            showFilter={showFilter}
                            interlocutors={interlocutors}
                            onValidFilter={onValidFilter}
                            changeValueFilter={changeValueFilter}
                        />
                    </div>
                    <div style={{ justifyContent: "start" }} className="filter">
                        <div className="filter-component">
                            <div>
                                <p>Trier par :</p>
                            </div>
                            <div>
                                <select onChange={(e) => { setSort(e.target.value); setCreditsList(null); /*setLoading(true)*/ }} >
                                    <option value="dateup">Date de création croissant<FontAwesomeIcon icon={faArrowUp} /></option>
                                    <option value="datedown">Date de création décroissant<FontAwesomeIcon icon={faArrowUp} /></option>
                                    <option value="refup">Références croissant</option>
                                    <option value="refdown">Références décroissant</option>
                                    <option value="follow">Suivi par</option>
                                    <option value="object">Objet</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="cards-list">

                        {<div className="cards-list">

                            {creditsList && creditsList.map((credit, k) => {

                                // console.log(credit.totalHT);
                                return (
                                    <CreditCard
                                        key={k}
                                        creditId={credit.creditId}
                                        creditRef={credit.ref}
                                        totalHt={credit.totalHT}
                                        totalTtc={credit.totalTTC}
                                        creditDate={credit.creditDate}
                                        contactId={credit.contactId}
                                        societyContact={credit.society}
                                        contactLastName={credit.LastName}
                                        contactFirstName={credit.FirstName}
                                        state={credit.state}
                                        valid={credit.valid}
                                    />
                                )
                            })
                            }
                        </div>

                        }
                    </div>
                </div>
                <TotalAmonts
                    totalTTC={totalTTC}
                    totalHT={totalHT}
                    totalVAT={totalVAT}
                />
                {/*!loading &&*/
                    (
                        <div className='pagination'>
                            <label htmlFor='perPage'>Éléments par page </label>
                            <select name='perPage' value={perPage} onChange={(e) => changePerPage(e)}>
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <Pagination
                                postsPerPage={perPage}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                nbPages={nbPages}
                            />
                        </div>
                    )}
        </>
    )
}

export default CreditList;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "../../../styles/style.css";
import Spinner from '../../addOns/Spinner';
import ContactCard from "./contactCard";
import {faPlus, faXmark} from "@fortawesome/free-solid-svg-icons";


function ContactsListPop(props) {
    const showHideClassName = props.show ? "modal-pop display-block" : "modal-pop display-none";

    const [contactList, setContactList] = useState();
    const [contactState, setContactState] = useState('all');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setContactList(props.data);
        setLoading(false)

        let all = document.getElementById('all')
        let customer = document.getElementById('customer')
        let provider = document.getElementById('provider')
        let prospect = document.getElementById('prospect')

        all.classList.remove('active')
        customer.classList.remove('active')
        provider.classList.remove('active')
        prospect.classList.remove('active')

        switch (contactState) {
            case 'all':
                all.classList.add('active')
                break;

            case 'customer':
                customer.classList.add('active')
                break;

            case 'provider':
                provider.classList.add('active')
                break;

            case 'prospect':
                prospect.classList.add('active')
                break;

            default:
                break;
        }
    }, [props, contactState]);
    const closeModal = () => {
        props.setShowModal(false);
    }

    // const addContact = (index) => {
    //     console.log("index", index);
    //     props.handleSelectedContact(index);
    // }

    return (
        <div className={showHideClassName}>
            <div className="">

                <div className="main">
                    {loading &&
                        (
                            <Spinner />
                        )
                    }
                    <div className="list">
                        <div className="toolbar">
                            <div className="title">
                                <h2>VOS <span>CLIENTS</span></h2>
                            </div>
                            <div className="button">
                                <Link to="/contactsList/contact">
                                    <FontAwesomeIcon icon={faPlus} />
                                    Nouveau client
                                </Link>
                            </div>
                            <FontAwesomeIcon className="xmark" icon={faXmark} onClick={closeModal} />
                        </div>
                        <div className="type">
                            <div className="all active" id='all'>
                                <h3 onClick={() => { setContactState('all'); setContactList(null); setLoading(true) }} >TOUS LES CLIENTS</h3>
                            </div>
                            <div className="valid" id='customer'>
                                <h3 onClick={() => { setContactState('customer'); setContactList(null); setLoading(true) }} >CLIENTS</h3>
                            </div>
                            <div className="signed" id='provider'>
                                <h3 onClick={() => { setContactState('provider'); setContactList(null); setLoading(true) }} >FOURNISSEURS</h3>
                            </div>
                            <div className="validated" id='prospect'>
                                <h3 onClick={() => { setContactState('prospect'); setContactList(null); setLoading(true) }} >PROSPECTS</h3>
                            </div>
                        </div>

                        <div className="search-bar" >
                        </div>
                        <div style={{ marginTop: "2rem" }} className="cards-list">
                            {contactList && contactList.map((contact, k) => {
                                return (
                                    <ContactCard
                                        showModal={props.show}
                                        setShowModal={props.setShowModal}
                                        // addContact={addContact}
                                        handleSelectedContact={props.handleSelectedContact}
                                        key={k}
                                        contactId={contact.id}
                                        refcontact={contact.ref}
                                        firstName={contact.firstName}
                                        lastName={contact.lastName}
                                        society={contact.society}
                                        adress={contact.adress1}
                                        CP={contact.postal}
                                        city={contact.city}
                                        mobilePhone={contact.mobilePhone}
                                        function={contact.function}
                                        type={contact.type}
                                    />
                                )
                            })
                            }


                        </div>
                    </div>

                    <a href="#!" alt="" className="add-button"><i className="fa-solid fa-plus"></i></a>
                </div>
            </div>
        </div>
    );
}

export default ContactsListPop;
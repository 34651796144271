import "../../../styles/style.css";

function OrderFilter(props) {

    const showHideClassName = props.showFilter ? "" : "hidden-filter";

    return (
        <div className={`card-filter ${showHideClassName}`}>
            <form onSubmit={props.onValidFilter}>
                <div className="row-one">
                    <input type="text" placeholder="Nom" name="lastName" title="Nom" onBlur={props.changeValueFilter}></input>
                    <input type="text" placeholder="Prénom" name="firstName" title="Prénom" onBlur={props.changeValueFilter}></input>
                    <input type="text" placeholder="Référence Devis" name="orderRef" title="Référence Devis" onBlur={props.changeValueFilter}></input>
                    <select name="interloSearch" onChange={props.changeValueFilter}>
                        <option>Suivit par</option>
                        {props.interlocutors?.map((interlocutor, k) => {
                            return (
                                <option key={interlocutor.id}>{interlocutor.last_name} {interlocutor.first_name}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <div className="row-two">
                    <input type="text" placeholder="Date de début" name="startDate" title="Date de début" onBlur={props.changeValueFilter}></input>
                    <input type="text" placeholder="Date de fin" name="endDate" title="Date de fin" onBlur={props.changeValueFilter}></input>
                    <input type="text" placeholder="Société" name="society" title="Société" onBlur={props.changeValueFilter}></input>
                    <input className="search" id="filter-submit" type="submit" value='Rechercher'></input>
                </div>
            </form>
        </div>
    )
}

export default OrderFilter;
import "../../../styles/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import {
    faCircleCheck, faClock,
    faCopy,
    faDownload,
    faEllipsisV, faEuroSign,
    faGear, faPaperPlane,
    faPrint, faTrashCan,
    faXmark
} from "@fortawesome/free-solid-svg-icons";

function OrderCard(props) {

    let navigate = useNavigate();
    // console.log(props?.state);
    useEffect(() => {
        // console.log(props.state);
        handleMark(props.state)
    })

    const printOrder = (id) => {
        axios.get(`${process.env.REACT_APP_ORDER_PRINT}${id}`)
            .then(response => {
                console.log(response.data);
                navigate("/orderList");
            })
            .catch(error => {
                console.log(error);
            })
    }
    const duplicate = (id) => {
        window.open(`${process.env.REACT_APP_ORDER_DUP_GET}${id}`, '_self');
    }
    const deleteOrder = (id) => {
        console.log(id);
        console.log(document.getElementById(id));
        document.getElementById(id).classList.add('hidden');
        axios.post(`${process.env.REACT_APP_ORDER_DELETE}${id}`)
            .then(response => {
                // console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })

    }


    //split date
    let day = props.orderDate.split("-")[2];
    let month = props.orderDate.split("-")[1];
    let year = props.orderDate.split("-")[0];

    let xmark = 'xmark' + props.orderId
    let option = 'option' + props.orderId
    let stateTextId = 'state-text' + props.orderId
    let mark = 'mark' + props.orderId

    const openOrder = (valid) => {
        console.log(valid);
        if (valid === 0) {
            window.open(`/ordersList/order/${props.orderId}`, '_self');
        } else {
            if (window.confirm('Cet avoir est vérouiller car il a été passé en facture.\r\n Voulez-vous malgré tout le dévérouiller ?')) {
                window.open(`/ordersList/order/${props.orderId}`, '_self');
            }
        }
    }


    const handleOptions = (id) => {
        let findXmark = 'xmark' + props.orderId
        let findOption = 'option' + props.orderId

        if (document.getElementById(findOption).classList.contains('option')) {
            document.getElementById(findOption).classList.remove('option')
            document.getElementById(findXmark).classList.remove('ellipsis-xmark')
            document.getElementById(findOption).classList.add('hidden')
        } else {
            document.getElementById(findOption).classList.add('option')
            document.getElementById(findXmark).classList.add('ellipsis-xmark')
            document.getElementById(findOption).classList.remove('hidden')
        }
    }


    const handleMark = async (val, id) => {

        let stateText = document.getElementById(stateTextId);
        let stateMark = document.getElementById(mark);

        if (val != null) {
            const customHeaders = {
                'content-type': 'application/json',
            };

            await axios.post(`http://localhost/CAPBOXV2-SERVER/index.php?action=c-state-order&id=${id}&state=${val}`, null, customHeaders)
                .then(response => {
                    // console.log("log de response data 1", response.data);
                })
                .catch(error => {
                    console.log(error);
                })
        }

        stateMark.classList.remove('valid')
        stateMark.classList.remove('unvalid')
        stateMark.classList.remove('draft')
        stateMark.classList.remove('canceled')


        switch (val) {
            case 'valid':
                stateText.innerText = 'validé';
                stateMark.classList.add('paid')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            case 'unvalid':
                stateText.innerText = 'Non validé';
                stateMark.classList.add('unpaid')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            case 'draft':
                stateText.innerText = 'Brouillon';
                stateMark.classList.add('draft')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            case 'canceled':
                stateText.innerText = 'Annulé';
                stateMark.classList.add('canceled')
                stateMark.classList.remove('hidden')
                stateText.classList.remove('hidden')
                break;
            default:
                break;
        }
    }
    return (

        <div className="card" id={props.orderId} key={props.orderId}>
            <div className="ellipsis">

                <div className="ellipsis-button" onClick={() => handleOptions(props.orderId)}>
                    <FontAwesomeIcon icon={faEllipsisV} />
                </div>
                <div id={xmark} className="hidden" onClick={() => handleOptions(props.orderId)}>
                    <FontAwesomeIcon icon={faXmark} />
                </div>
                <div id={option} className="hidden">
                    <div className="modify">
                        <Link to={`/order/${props.orderId}`} className="orderLink">
                            <p><FontAwesomeIcon icon={faGear} />Modifier</p>
                        </Link>
                    </div>
                    <div className="mark">
                        <p className="valid paid" onClick={() => handleMark('valid', props.orderId)}><FontAwesomeIcon icon={faCircleCheck} />Marquer comme payé</p>
                        <p className="unvalid unpaid" onClick={() => handleMark('unvalid', props.orderId)}><FontAwesomeIcon icon={faCircleCheck} />Marquer comme non payé</p>
                        <p className="canceled" onClick={() => handleMark('canceled', props.orderId)}><FontAwesomeIcon icon={faCircleCheck} />Marquer comme annulé</p>
                    </div>
                    <div className="download">
                        <p><FontAwesomeIcon icon={faDownload} />Télécharger</p>
                        <p><FontAwesomeIcon icon={faPaperPlane} />Envoyer</p>
                        <p onClick={() => printOrder(props.orderId)}><FontAwesomeIcon icon={faPrint} />Imprimer</p>
                    </div>
                    <div className="duplicate">
                        <p onClick={() => duplicate(props.orderId)}><FontAwesomeIcon icon={faCopy} />Dupliquer</p>
                    </div>
                    <div className="delete">
                        <p className="trash-can" onClick={() => deleteOrder(props.orderId)}><FontAwesomeIcon icon={faTrashCan} />Supprimer</p>
                    </div>
                </div>
            </div>
            <div className="tileCard" onClick={() => openOrder(props.valid)}>

                <div className="state valid">
                    <p className="ref">DEPENSE N° <span>{props.refOrder}</span></p>
                    <p className="state-text hidden" id={stateTextId}></p>
                    <span id={mark} className="hidden"><FontAwesomeIcon icon={faCircleCheck} /></span>
                </div>
                <div className="name">
                    <p>{props.contactLastName} {props.contactFirstName} {props.contactFirstName && props.contactLastName ? `-` : ""} {props.societyContact} </p>
                </div>
                <div className="price">
                    <FontAwesomeIcon icon={faEuroSign} />
                    <p className="ht">{parseFloat(props.totalHT.replace(',', '.')).toFixed(2)} € HT</p>
                    <p className="ttc">{parseFloat(props.totalTTC.replace(',', '.')).toFixed(2)} € TTC</p>
                </div>
                <div className="date">
                    <FontAwesomeIcon icon={faClock} />
                    <p>{day}/{month}/{year}</p>
                </div>
            </div>
        </div>



    )
}

export default OrderCard;